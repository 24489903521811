<template>
	<div :data-error="$props.errors.length > 0">
		<slot />
		<ul v-if="errors">
			<li v-for="error in errors" :key="error">{{ error }}</li>
		</ul>
	</div>
</template>

<script>
export default {
	props: {
		errors: {
			type: Array,
			default() {
				return [];
			},
		},
	},
};
</script>

<style scoped lang="scss">
ul {
	padding-left: 0.4rem;
	list-style-position: inside;
	list-style-type: disc;
	color: var(--red);
}

[data-error] {
	--border-color: var(--red);
}
</style>
