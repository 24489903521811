<template>
	<b-container class="text-center">
		<div class="h3">{{ $t('payments.confirmPurchase') }}</div>
		<div class="confirmation-text">
			<div class="label">{{ $t('amount') }}:</div>
			<div class="value">{{ creditsToBuy }} {{ $tc('minutes', creditsToBuy) }}</div>
			<div class="label">{{ $t('total') }}</div>
			<div class="value">R${{ priceToPay }}</div>
		</div>
		<credit-card :encrypted-card="encryptedCard" />
		<div class="save-card">
			<b-form-checkbox id="saveCard" v-model="localSaveCard" />
			<label for="saveCard">{{ $t('payments.saveCard') }}</label>
		</div>
		<credits-modal-buttons @step-forward="$emit('step-forward')" @step-backward="$emit('step-backward')">
			<template #backward>
				<i class="mr-1 fas fa-angle-double-left"></i>
				<strong>{{ $t('back') }}</strong>
			</template>
			<template #forward>
				<strong>{{ $t('confirm') }}</strong>
				<i class="ml-1 fas fa-check"></i>
			</template>
		</credits-modal-buttons>
	</b-container>
</template>

<script>
import CreditsModalButtons from './CreditsModalButtons.vue';
import CreditCard from './CreditCard/CreditCard.vue';
import calculateCharge from '@/util/calculateCharge';

export default {
	components: {
		CreditsModalButtons,
		CreditCard,
	},
	props: {
		creditsToBuy: {
			type: Number,
			required: true,
		},
		encryptedCard: {
			type: Object,
			required: true,
		},
		saveCard: {
			type: Boolean,
			required: true,
		},
	},

	data() {
		return {
			localSaveCard: this.saveCard,
		};
	},

	computed: {
		priceToPay: function () {
			return calculateCharge(this.creditsToBuy);
		},
	},

	watch: {
		localSaveCard: function () {
			this.$emit('save-card-change', this.localSaveCard);
		},
	},
};
</script>

<style lang="scss" scoped>
.confirmation-text {
	margin-block: 1rem;
	font-size: 1.4rem;
	display: grid;
	max-width: 320px;
	margin-inline: auto;
	grid-template-columns: 1fr 1fr;
	gap: 0.5rem;

	.label {
		text-align: left;
		opacity: 0.8;
	}

	.value {
		text-align: right;
		font-weight: 600;
	}
}

.save-card {
	display: flex;
	justify-content: center;
	margin-top: 1rem;
}
</style>
