<template>
	<div id="LoginPage">
		<div class="m-3" style="position: absolute">
			<language-switcher />
		</div>
		<div class="container">
			<div class="row no-gutters align-items-center justify-content-center vh-100">
				<div class="col col-xs-8 col-xxl-6">
					<card-glass custom-class="shadow">
						<h3>{{ $t('finishSettingUpAccount') }}</h3>
						<div class="mt-4">
							<h5>{{ $t('fillTheInfoBelow') }}</h5>
							<div class="form-row">
								<div class="form-group col-7">
									<label for="name">{{ $t('name') }}</label>
									<input id="name" ref="name" v-model="name" disabled type="text" class="form-control" name="name" />
								</div>
								<div class="form-group col-5">
									<label for="company">{{ $t('company') + ' (' + $t('optional') + ')' }}</label>
									<input
										id="company"
										v-model="company"
										:disabled="isBusy"
										type="text"
										class="form-control"
										name="company"
										aria-describedby="companyHelp"
										placeholder="ex.: Skylar"
									/>
								</div>
							</div>
							<div class="form-row">
								<div class="form-group col-6">
									<label for="email">{{ $t('email') }}</label>
									<input id="email" v-model="email" disabled class="form-control" type="email" name="email" />
								</div>

								<div class="form-group col-3">
									<label for="password">{{ $t('password') }}</label>
									<input
										id="password"
										v-model="password"
										:disabled="isBusy"
										type="password"
										class="form-control"
										name="password"
										:placeholder="$t('password')"
									/>
								</div>
								<div class="form-group col-3">
									<label for="confirmPassword">{{ $t('confirmPassword') }}</label>
									<input
										id="confirmPassword"
										v-model="confirmPassword"
										:disabled="isBusy"
										class="form-control"
										type="password"
										name="confirmPassword"
										:placeholder="$t('confirmPassword')"
									/>
								</div>
							</div>
							<div class="form-row">
								<div class="form-group col-2">
									<label for="countryCode">{{ $t('countryCode') }}</label>
									<vue-country-code :preferred-countries="['br']" @onSelect="onSelect"> </vue-country-code>
								</div>
								<div class="form-group col-2">
									<label for="areaCode">{{ $t('areaCode') }}</label>
									<the-mask
										id="areaCode"
										v-model="phoneNumber.areaCode"
										:mask="['(##)']"
										:disabled="isBusy"
										class="form-control"
										name="areaCode"
										placeholder="19"
									/>
								</div>
								<div class="form-group col-4">
									<label for="phoneNumber">{{ $t('phoneNumber') }}</label>
									<the-mask
										id="phoneNumber"
										v-model="phoneNumber.number"
										:mask="['####-####', '#####-####']"
										:disabled="isBusy"
										class="form-control"
										name="phoneNumber"
										placeholder="99999-9999"
									/>
								</div>
								<div class="form-group col-4">
									<label for="stateRegistration">{{ $t('state') }}</label>
									<b-form-select
										id="stateRegistration"
										v-model="stateRegistration"
										:options="stateOptions"
										:disabled="isBusy"
										name="documentType"
									/>
								</div>
							</div>
							<div class="form-row">
								<div class="form-group col-3">
									<label for="documentType">{{ $t('documentType') }}</label>
									<b-form-select
										id="documentType"
										v-model="documentType"
										:options="documentOptions"
										:disabled="isBusy"
										name="documentType"
									/>
								</div>
								<div class="form-group col-6">
									<label for="document">{{ $t('document') }}</label>
									<!-- <input
										id="document"
										mask
										v-model="document"
										:disabled="isBusy"
										type="number"
										class="form-control"
										name="document"
										:placeholder="$t('document')"
									/> -->
									<the-mask
										id="document"
										v-model="document"
										:mask="['###.###.###-##', 'XXXXXXXXXX']"
										:disabled="isBusy"
										class="form-control"
										name="document"
										:placeholder="$t('document')"
									/>
								</div>
								<div class="form-group col-5"></div>
							</div>
							<div class="form-row">
								<div class="form-group col-10 border rounded m-2 p-2">
									<confidential-subtitles-checkbox
										v-model="confidentialSubtitles"
										:disabled="isBusy"
										help-class="text-white"
									/>
								</div>
							</div>
						</div>
						<b-button class="signup-btn mt-4 text-uppercase" size="lg" :disabled="isBusy" @click="validateFields">{{
							$t('finishSignup')
						}}</b-button>
					</card-glass>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import LanguageSwitcher from '../components/LanguageSwitcher.vue';
import VueCountryCode from 'vue-country-code';
import Vue from 'vue';
import { TheMask } from 'vue-the-mask';
import ConfidentialSubtitlesCheckbox from '../components/ConfidentialSubtitlesCheckbox.vue';

Vue.use(VueCountryCode);

export default {
	name: 'FinishSignup',
	components: { LanguageSwitcher, ConfidentialSubtitlesCheckbox, TheMask },

	props: {},

	data() {
		return {
			company: '',
			confidentialSubtitles: false,
			confirmPassword: '',
			document: '',
			documentType: '',
			documentOptions: [
				{ value: 'cpf', text: 'CPF' },
				{ value: 'passport', text: 'Passaporte' },
			],
			email: '',
			inviteCode: '',
			isBusy: false,
			name: '',
			password: '',
			phoneNumber: {
				areaCode: '',
				countryCode: '55',
				number: '',
			},
			stateRegistration: '',
			stateOptions: [
				{ value: 'AC', text: 'Acre' },
				{ value: 'AL', text: 'Alagoas' },
				{ value: 'AP', text: 'Amapá' },
				{ value: 'AM', text: 'Amazonas' },
				{ value: 'BA', text: 'Bahia' },
				{ value: 'CE', text: 'Ceará' },
				{ value: 'DF', text: 'Distrito Federal' },
				{ value: 'ES', text: 'Espírito Santo' },
				{ value: 'GO', text: 'Goías' },
				{ value: 'MA', text: 'Maranhão' },
				{ value: 'MT', text: 'Mato Grosso' },
				{ value: 'MS', text: 'Mato Grosso do Sul' },
				{ value: 'MG', text: 'Minas Gerais' },
				{ value: 'PA', text: 'Pará' },
				{ value: 'PB', text: 'Paraíba' },
				{ value: 'PR', text: 'Paraná' },
				{ value: 'PE', text: 'Pernambuco' },
				{ value: 'PI', text: 'Piauí' },
				{ value: 'RJ', text: 'Rio de Janeiro' },
				{ value: 'RN', text: 'Rio Grande do Norte' },
				{ value: 'RS', text: 'Rio Grande do Sul' },
				{ value: 'RO', text: 'Rondônia' },
				{ value: 'RR', text: 'Roraíma' },
				{ value: 'SC', text: 'Santa Catarina' },
				{ value: 'SP', text: 'São Paulo' },
				{ value: 'SE', text: 'Sergipe' },
				{ value: 'TO', text: 'Tocantins' },
			],
		};
	},

	beforeMount() {},

	async mounted() {
		const loader = this.$loading.show();
		this.inviteCode = this.$route.query.code;
		if (!this.inviteCode) this.$router.push('/login');
		try {
			const functions = Vue.firebase.functions();
			const getInvite = functions.httpsCallable('users-getInvite');
			let inviteData = await getInvite({
				inviteId: this.inviteCode,
			});
			inviteData = inviteData.data;
			this.name = inviteData.name;
			this.email = inviteData.guest;

			//Verify if email is already registered
			const verifyUserDocExists = functions.httpsCallable('users-verifyUserDocExists');
			let userDocExists = await verifyUserDocExists({
				email: this.email,
			});
			if (userDocExists.data) {
				Vue.swal({
					icon: 'error',
					title: 'Oops...',
					text: this.$t('userAlreadyExists'),
					confirmButtonText: 'Voltar para o login',
				}).then(() => {
					this.$router.push('/login');
				});
			}
			loader.hide();
		} catch (err) {
			loader.hide();
			Vue.swal({
				icon: 'error',
				title: 'Oops...',
				text: err.message,
				confirmButtonText: 'Voltar para o login',
			}).then(() => {
				this.$router.push('/login');
			});
			console.error(err);
		}
	},

	methods: {
		onSelect({ dialCode }) {
			this.phoneNumber.countryCode = dialCode;
		},
		validateFields() {
			const loader = this.$loading.show();
			this.setBusy(true);
			//Validate password
			if (!this.validatePassword()) {
				loader.hide();
				this.setBusy(false);
				return;
			}
			//Validate phone fields
			if (this.phoneNumber.areaCode < 11 || this.phoneNumber.areaCode.length != 2) {
				loader.hide();
				Vue.swal({
					icon: 'warning',
					title: 'Oops...',
					text: this.$t('invalidAreaCode'),
					showCancelButton: false,
					confirmButtonText: this.$t('back'),
				});
				this.setBusy(false);
				return;
			}
			if (this.phoneNumber.number.length != 9) {
				loader.hide();
				Vue.swal({
					icon: 'warning',
					title: 'Oops...',
					text: this.$t('invalidPhoneNumber'),
					showCancelButton: false,
					confirmButtonText: this.$t('back'),
				});
				this.setBusy(false);
				return;
			}
			//Validate state
			if (this.stateRegistration == '') {
				loader.hide();
				Vue.swal({
					icon: 'warning',
					title: 'Oops...',
					text: this.$t('invalidState'),
					showCancelButton: false,
					confirmButtonText: this.$t('back'),
				});
				this.setBusy(false);
				return;
			}
			//Validate document
			if (this.documentType == '') {
				loader.hide();
				Vue.swal({
					icon: 'warning',
					title: 'Oops...',
					text: this.$t('invalidDocumentType'),
					showCancelButton: false,
					confirmButtonText: this.$t('back'),
				});
				this.setBusy(false);
				return;
			}
			if (this.documentType == 'cpf' && !this.validateCPF(this.document)) {
				loader.hide();
				Vue.swal({
					icon: 'warning',
					title: 'Oops...',
					text: this.$t('invalidCPF'),
					showCancelButton: false,
					confirmButtonText: this.$t('back'),
				});
				this.setBusy(false);
				return;
			}
			if (this.document == '') {
				loader.hide();
				Vue.swal({
					icon: 'warning',
					title: 'Oops...',
					text: this.$t('invalidDocument'),
					showCancelButton: false,
					confirmButtonText: this.$t('back'),
				});
				this.setBusy(false);
				return;
			}
			this.registerUser();
			loader.hide();
			return;
		},
		validatePassword() {
			/* eslint-disable */
			const minLength = 8;
			const regexLetrasMinusculas = /[a-z]/;
			const regexLetrasMaiusculas = /^(?=.*[A-Z]).{0,}$/;
			const regexNumeros = /[0-9]/;
			const regexCaracteresEspeciais = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
			/* eslint-enable */

			if (this.password !== this.confirmPassword) {
				Vue.swal({
					icon: 'warning',
					title: 'Oops...',
					text: this.$t('passwordNotMatch'),
					showCancelButton: false,
					confirmButtonText: this.$t('back'),
				});
				return false;
			}

			if (!this.password || this.password.length < minLength) {
				Vue.swal({
					icon: 'warning',
					title: 'Oops...',
					text: this.$t('passwordTooShort'),
					showCancelButton: false,
					confirmButtonText: this.$t('back'),
				});
				return false;
			}

			if (!regexLetrasMinusculas.test(this.password)) {
				Vue.swal({
					icon: 'warning',
					title: 'Oops...',
					text: this.$t('passwordMustContainLowercase'),
					showCancelButton: false,
					confirmButtonText: this.$t('back'),
				});
				return false;
			}

			if (!regexLetrasMaiusculas.test(this.password)) {
				Vue.swal({
					icon: 'warning',
					title: 'Oops...',
					text: this.$t('passwordMustContainUppercase'),
					showCancelButton: false,
					confirmButtonText: this.$t('back'),
				});
				return false;
			}

			if (!regexNumeros.test(this.password)) {
				Vue.swal({
					icon: 'warning',
					title: 'Oops...',
					text: this.$t('passwordMustContainNumber'),
					showCancelButton: false,
					confirmButtonText: this.$t('back'),
				});
				return false;
			}

			if (!regexCaracteresEspeciais.test(this.password)) {
				Vue.swal({
					icon: 'warning',
					title: 'Oops...',
					text: this.$t('passwordMustContainSpecial'),
					showCancelButton: false,
					confirmButtonText: this.$t('back'),
				});
				return false;
			}

			return true;
		},
		validateCPF(cpf) {
			let sum;
			let rest;
			sum = 0;
			if (cpf == '00000000000') return false;

			for (let i = 1; i <= 9; i++) sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
			rest = (sum * 10) % 11;

			if (rest == 10 || rest == 11) rest = 0;
			if (rest != parseInt(cpf.substring(9, 10))) return false;

			sum = 0;
			for (let i = 1; i <= 10; i++) sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
			rest = (sum * 10) % 11;

			if (rest == 10 || rest == 11) rest = 0;
			if (rest != parseInt(cpf.substring(10, 11))) return false;
			return true;
		},
		async registerUser() {
			try {
				const functions = Vue.firebase.functions();
				const registerUser = functions.httpsCallable('users-registerUser');
				await registerUser({
					form: {
						company: this.company,
						confidentialSubtitles: this.confidentialSubtitles,
						document: this.document,
						documentType: this.documentType,
						email: this.email,
						inviteCode: this.inviteCode,
						name: this.name,
						password: this.password,
						phoneNumber: {
							areaCode: this.phoneNumber.areaCode,
							countryCode: this.phoneNumber.countryCode,
							number: this.phoneNumber.number,
						},
						stateRegistration: this.stateRegistration,
					},
				});
				Vue.swal({
					icon: 'sucess',
					title: this.$t('userRegisterSuccess'),
					// text: this.$t(''),
					confirmButtonText: this.$t('backToLogin'),
				}).then(() => {
					this.$router.push('/#/login');
				});
			} catch (error) {
				console.error(error.code);
				console.error(error);
				Vue.swal({
					icon: 'error',
					title: 'Oops...',
					text: error,
				});
				this.setBusy(false);
			}
		},
		setBusy(value) {
			this.isBusy = value;
		},
	},
};
</script>

<style lang="scss" scoped>
.signup-btn {
	background-color: #ff0279;
	border-color: #ff0279;
	margin-left: 34%;
}
</style>
