import Vue from 'vue';

const handleEmailAction = (mode, actionCode) => {
	return new Promise((resolve, reject) => {
		const auth = Vue.firebase.auth();
		switch (mode) {
			case 'resetPassword':
				// Display reset password handler and UI.
				auth
					.verifyPasswordResetCode(actionCode)
					.then((email) => {
						resolve({ name: 'ResetPassword', params: { resetPassword: true, actionCode, email } });
					})
					.catch((err) => {
						reject(new Error('Error: action code invalid.', err));
					});
				break;
			// case 'recoverEmail':
			// 	// Display email recovery handler and UI.
			// 	handleRecoverEmail(auth, actionCode, lang);
			// 	break;
			// case 'verifyEmail':
			// 	// Display email verification handler and UI.
			// 	handleVerifyEmail(auth, actionCode, continueUrl, lang);
			// 	break;
			default:
				// Error: invalid mode.
				reject(new Error('Error: invalid mode.'));
		}
	});
};

export default function emailActionHandler({ router }) {
	const queryString = window.location.href.substring(window.location.href.indexOf('?'));
	const urlParams = new URLSearchParams(queryString);

	// Get the action to complete.
	const mode = urlParams.get('mode');
	// Get the one-time code from the query parameter.
	const actionCode = urlParams.get('oobCode');

	handleEmailAction(mode, actionCode)
		.then((resp) => {
			router.push(resp);
		})
		.catch((err) => {
			console.error(err);
			router.push('/NotFound');
		});
}
