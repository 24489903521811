<template>
	<b-dropdown
		id="dropdown-right"
		ref="dropdown"
		right
		no-caret
		text="Right align"
		variant="primary"
		class="m-2"
		menu-class="notification-container"
	>
		<template #button-content>
			<i class="fa-solid fa-bell" style="font-size: 1.5rem; line-height: 1"></i
			><transition name="bounce">
				<b-badge
					v-if="notificationUnreadCount > 0"
					:key="notificationUnreadCount"
					variant="pink"
					class="notification-count"
					>{{ notificationUnreadCount }}</b-badge
				>
			</transition></template
		>
		<b-dropdown-header id="dropdown-header">
			<button class="read-all-button" @click="clearNotifications">{{ $t('notifications.clearAll') }}</button>
		</b-dropdown-header>
		<b-dropdown-divider></b-dropdown-divider>
		<notifications-container :notifications="sortedNotifications" />
	</b-dropdown>
</template>
<script>
import Vue from 'vue';
import NotificationsContainer from './NotificationsContainer.vue';

export default {
	components: {
		NotificationsContainer,
	},
	provide() {
		return {
			dropdownRef: this.$refs.dropdown,
		};
	},
	data() {
		return {
			notifications: [],
			unsubscribe: null,
		};
	},
	computed: {
		getUserId() {
			return this.$store.getters['user/getUserId'];
		},
		getCloseDropdown() {
			return this.$store.getters['getCloseDropdown'];
		},
		notificationUnreadCount() {
			return this.notifications.filter((notification) => !notification.read).length;
		},
		sortedNotifications() {
			return [...this.notifications].sort((a, b) => b.time - a.time);
		},
	},
	watch: {
		getCloseDropdown() {
			this.$refs.dropdown.hide(true);
		},
	},
	async created() {
		this.startListenerNotification();
	},
	beforeDestroy() {
		this.unsubscribe(); // Caso seja necessário fechar esse componenete, é necessário remover o listener.
	},
	methods: {
		startListenerNotification() {
			const db = Vue.firebase.firestore();
			const notificationsRef = db.collection(`users/${this.getUserId}/notifications`);
			this.unsubscribe = notificationsRef.where('deleted', '==', false).onSnapshot((querySnapshot) => {
				this.notifications = [];
				querySnapshot.forEach((doc) => {
					this.notifications.push({ id: doc.id, ...doc.data() });
				});
			});
		},
		async clearNotifications() {
			const db = Vue.firebase.firestore();
			const notificationsRef = db.collection(`users/${this.getUserId}/notifications/`);
			const querySnapshot = await notificationsRef.get();

			querySnapshot.forEach(async (doc) => {
				try {
					await doc.ref.update({ read: true });
				} catch (error) {
					console.error(`Erro marcando notificação ${doc.id} como lida:`, error);
				}
			});
		},

		async handleNavigate() {
			// this.$refs.dropdown.hide(true);
		},
	},
};
</script>
<style scoped lang="scss">
#dropdown-header {
	font-size: 1.1rem;
	text-align: center;
	font-weight: 500;
	margin-block: 0.5rem;
}

#dropdown-right > :deep(button) {
	background: none;
	border: none;
	margin-left: 0.5rem;
	position: relative;
	display: flex;
	align-items: center;

	.notification-count {
		font-size: 0.75rem;
		font-weight: 800;
		position: absolute;
		right: 0.2rem;
		display: grid;
		place-items: center;
		padding: 0;
		line-height: 1.6em;
		width: 1.6em;
	}
}

#dropdown-right :deep(ul) {
	padding: 0;
}

#dropdown-right :deep(hr) {
	margin-bottom: 0;
}

.read-all-button {
	color: var(--primary);
	background: none;
	border: none;
}

.bounce-enter-active {
	animation: bounce-in 0.4s;
}

.bounce-leave-active {
	animation: bounce-in 0.2s reverse;
}

@keyframes bounce-in {
	0% {
		transform: scale(0);
	}
	50% {
		transform: scale(1.3);
	}
	100% {
		transform: scale(1);
	}
}
</style>
