<template>
	<notifications-item-base
		:title="notification.title"
		:read="notification.read"
		:link="`/payments/?id=${notification.data.id}`"
		:body="notification.body"
		:time="notification.time"
		:icon="icon"
		:color="color"
		:notification-id="notification.id"
	/>
</template>

<script>
import NotificationsItemBase from './NotificationsItemBase.vue';

export default {
	components: {
		NotificationsItemBase,
	},
	props: {
		notification: {
			type: Object,
			required: true,
		},
	},
	computed: {
		icon() {
			switch (this.notification.data.status) {
				case 'Open':
					return 'fa-solid fa-circle-info';
				case 'Paid':
					return 'fa-solid fa-check';
				case 'Failed':
					return 'fa-solid fa-warning';
				default:
					return 'fa-solid fa-circle-info';
			}
		},
		color() {
			switch (this.notification.data.status) {
				case 'Open':
					return 'blue';
				case 'Paid':
					return 'green';
				case 'Failed':
					return 'red';
				default:
					return 'blue';
			}
		},
	},
};
</script>
