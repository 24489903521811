<template>
	<div class="cc">
		<img class="cc-brand" :src="cardLogo" />
		<div class="cc-number">{{ encryptedCard.lastFourDigits }}</div>
		<div class="cc-date">{{ encryptedCard.expirationMonth }} / {{ encryptedCard.expirationYear }}</div>
	</div>
</template>

<script>
import EloLogo from '@/assets/svg/card-elo.svg';
import VisaLogo from '@/assets/svg/card-visa.svg';
import MastercardLogo from '@/assets/svg/card-mastercard.svg';
import GenericLogo from '@/assets/svg/card-generic.svg';

export default {
	props: {
		encryptedCard: {
			type: Object,
			required: true,
		},
	},
	computed: {
		cardLogo() {
			switch (this.encryptedCard.brand) {
				case 'Visa':
					return VisaLogo;
				case 'Mastercard':
					return MastercardLogo;
				case 'Elo':
					return EloLogo;
				default:
					return GenericLogo;
			}
		},
	},
};
</script>

<style scoped lang="scss">
.cc-number::before {
	content: '···· ···· ···· ';
	display: inline-block;
	letter-spacing: -1px;
	margin-right: 0.2rem;
}

.cc {
	font-size: 1.2rem;
	box-shadow: 2px 2px 9px 0px rgba(0, 0, 0, 0.08);
	border: 1px solid #00000020;
	margin-top: 2rem;
	padding: 0.5rem 2rem;
	font-weight: 700;
	display: flex;
	width: fit-content;
	margin-inline: auto;
	align-items: center;
	justify-content: center;
	gap: 2rem;
	border-radius: 0.2rem;
}

.cc-brand {
	height: 1.4rem;
}
</style>
