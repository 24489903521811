export const state = () => ({
	isRecording: false,
	recordingId: null,
});

export const getters = {
	isRecording: (state) => state.isRecording,
	recordingId: (state) => state.recordingId,
};

export const mutations = {
	setRecording: (state, { isRecording, recordingId }) => {
		state.isRecording = isRecording;
		state.recordingId = recordingId;
	},
};

export default {
	state,
	getters,
	mutations,
	namespaced: true,
};
