<template>
	<b-container>
		<div v-show="loading" class="p-3">
			<div class="h3 text-center">{{ $t('payments.confirmingPayment') }}</div>
			<b-spinner variant="pink" class="mx-auto d-block my-4" style="width: 3rem; height: 3rem" />
		</div>

		<div v-show="!loading && !error" class="text-center">
			<div class="text-pink h4 mt-4 mb-2"><i class="fas fa-check" style="font-size: 3rem" /></div>
			<div class="h3 text-center">{{ $t('payments.paymentRequested') }}</div>
			<p>{{ $t('payments.creditsWillBeAvaliable') }}</p>
			<credits-modal-buttons @step-forward="$emit('step-forward')" @step-backward="$emit('step-backward')">
				<template #forward>
					<strong>{{ $t('close') }}</strong>
				</template>
			</credits-modal-buttons>
		</div>

		<div v-show="!loading && error && tryCount <= 2" class="text-center">
			<div class="text-pink h4 mt-4 mb-2"><i class="fas fa-triangle-exclamation" style="font-size: 3rem" /></div>
			<div class="h3 text-center">{{ $t('somethingWentWrong') }}</div>
			<p>{{ $t('payments.problem') }}</p>
			<credits-modal-buttons @step-backward="$emit('step-backward')" @step-forward="startPayment">
				<template #backward>
					<i class="mr-1 fas fa-angle-double-left"></i>
					<strong>{{ $t('back') }}</strong>
				</template>
				<template #forward>
					<strong>{{ $t('tryAgain') }}</strong>
					<i class="ml-1 fas fa-redo"></i>
				</template>
			</credits-modal-buttons>
		</div>

		<div v-show="!loading && error && tryCount >= 3" class="text-center">
			<i class="fas fa-triangle-exclamation text-red h4 mt-4" style="font-size: 3rem" />
			<div class="h3 text-center">{{ $t('somethingWentWrong') }}</div>
			<p>{{ $t('payments.problem') }}</p>
			<p>{{ $t('payments.tryAgainLater') }}</p>
			<credits-modal-buttons @step-forward="$emit('step-forward')">
				<template #forward>
					<strong>{{ $t('close') }}</strong>
					<i class="ml-1 fas fa-times"></i>
				</template>
			</credits-modal-buttons>
		</div>
	</b-container>
</template>

<script>
import CreditsModalButtons from './CreditsModalButtons.vue';
import Vue from 'vue';

export default {
	components: {
		CreditsModalButtons,
	},

	props: {
		creditsToBuy: {
			type: Number,
			required: true,
		},
		creditCardInfo: {
			type: Object,
			required: true,
		},
		billingAddress: {
			type: Object,
			required: true,
		},
		userDocument: {
			type: Object,
			required: true,
		},
		encryptedCard: {
			type: Object,
			required: true,
		},
	},

	data() {
		return { loading: true, error: false, tryCount: 0 };
	},

	computed: {
		priceToPay: function () {
			return this.creditsToBuy * this.pricePerMinute;
		},
	},

	created() {
		this.startPayment();
		this.delayClose();
	},

	methods: {
		async startPayment() {
			this.loading = true;
			this.error = false;

			const functions = Vue.firebase.functions();

			const createPayment = functions.httpsCallable('payments-createNewPayment');

			this.$emit('set-prevent-close', true);

			const payload = {
				minutes: this.creditsToBuy,
				billingAddress: this.billingAddress,
				creditCardLimit: this.creditCardInfo,
				customer: this.userDocument,
			};

			console.dir(payload);

			try {
				const { data: result } = await createPayment(payload);
				this.loading = false;

				if (result.errors) {
					console.log('Errors:', result.errors);
					this.error = result.errors;
					this.tryCount++;
					this.$emit('set-prevent-close', false);
					return;
				}
			} catch (e) {
				console.error(e);
				this.error = true;
			}

			this.$emit('set-prevent-close', false);
		},

		async delayClose() {
			await new Promise(function (resolve) {
				setTimeout(resolve, 2000);
			});
			this.$emit('set-prevent-close', false);
		},
	},
};
</script>
