export const AllYears = [
	{ value: '', text: 'Ano', disabled: true },
	{ value: '2023', text: '23' },
	{ value: '2024', text: '24' },
	{ value: '2025', text: '25' },
	{ value: '2026', text: '26' },
	{ value: '2027', text: '27' },
	{ value: '2028', text: '28' },
	{ value: '2029', text: '29' },
	{ value: '2030', text: '30' },
	{ value: '2031', text: '31' },
	{ value: '2032', text: '32' },
];
