<template>
	<b-form-checkbox v-model="checked" v-bind="$attrs">
		{{ $t('confidentialSubtitles.label') }}
		<small class="form-text" :class="helpClass">
			{{ $t('confidentialSubtitles.helpText') }}
		</small>
	</b-form-checkbox>
</template>

<script>
export default {
	inheritAttrs: false,

	props: {
		value: {
			type: Boolean,
			default: false,
		},
		helpClass: {
			type: String,
			default: 'text-muted',
		},
	},

	data() {
		return {
			checked: this.value,
		};
	},

	watch: {
		checked(newValue) {
			this.$emit('input', newValue);
		},
	},
};
</script>
