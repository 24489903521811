<template>
	<div class="credits-display mx-4">
		<template v-if="isPrepaidPlan">
			<div v-b-tooltip.hover class="credits credits-left" :title="fulltimeLeft">
				<i class="fa-solid fa-clock mr-2"></i>
				<strong>{{ minutesAvaliable }}</strong>
				<div class="label">{{ $tc('minutes', minutesAvaliable) }}</div>
			</div>
			<button class="btn" @click="$emit('click')">{{ $t('payments.addMinutesButton') }}</button>
		</template>
		<!-- <div v-else v-b-tooltip.hover class="credits credits-used" :title="fulltimeUsed">
			<i class="fa-solid fa-clock mr-2"></i>
			<strong>{{ totalUsedDuration }}</strong>
			<div class="label">{{ $tc('minutes', totalUsedDuration) }}</div>
		</div> -->
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import Vue from 'vue';

export default {
	data() {
		return {
			finalizedEventsTotal: 0,
			openEventsTotal: 0,
			unsubscribe: null,
		};
	},
	computed: {
		fulltimeLeft() {
			if (!this.minutesAvaliable) {
				return this.$t('payments.noMinutes');
			}

			const { hours, minutes } = this.formatTime(this.minutesAvaliable);

			return (
				this.$t('payments.timeAvaliable') +
				this.$tc('payments.hours', hours, { hours: hours }) +
				' ' +
				this.$tc('payments.minutes', minutes, { minutes: minutes })
			);
		},

		fulltimeUsed() {
			if (!this.totalUsedDuration) {
				return this.$t('payments.noTimeUsed');
			}

			const { hours, minutes } = this.formatTime(this.totalUsedDuration);

			return (
				this.$t('payments.timeUsed') +
				this.$tc('payments.hours', hours, { hours: hours }) +
				' ' +
				this.$tc('payments.minutes', minutes, { minutes: minutes }) +
				this.$t('payments.thisMonth')
			);
		},
		...mapGetters({
			userCredit: 'user/getUserCredit',
			isPrepaidPlan: 'user/isPrepaidPlan',
			userId: 'user/getUserId',
		}),
		minutesAvaliable() {
			return Math.floor(this.userCredit / 60 / 1000); // credit is set as milliseconds on firebase
		},

		totalUsedDuration() {
			return Math.floor(this.finalizedEventsTotal + this.openEventsTotal);
		},
	},
	watch: {
		// Check if the user data is fully loaded
		isPrepaidPlan: {
			handler: function (value) {
				if (value === false) {
					this.subscribeTotalUsageTime();
				}
			},
			immediate: true,
		},
	},
	beforeDestroy() {
		if (!this.isPrepaidPlan) {
			if (this.unsubscribe) this.unsubscribe();
		}
	},
	methods: {
		formatTime(totalMinutes) {
			const hours = Math.floor(totalMinutes / 60);
			const minutes = Math.floor(totalMinutes % 60);

			return { hours, minutes };
		},

		startOfMonth() {
			const now = new Date();
			const year = now.getFullYear();
			const month = now.getMonth();

			// Create a new Date object for the start of the month
			const startOfMonth = new Date(year, month, 1, 0, 0, 0, 0);
			return startOfMonth.getTime();
		},

		subscribeTotalUsageTime() {
			const db = Vue.firebase.firestore();
			const eventsRef = db.collection(`users/${this.userId}/events`);

			// Usamos uma query com get para eventos finalizados. Assim não precisamos
			// re-fazer essa conta sempre que o tempo de um evento aberto muda.
			eventsRef
				.where('start_date', '>=', this.startOfMonth().toString())
				.where('status', '==', 'finalized')
				.get()
				.then((querySnapshot) => {
					let total = 0;
					querySnapshot.forEach((doc) => {
						// `time_spent `é acrescentado pela API somente quando realmente há transcrição
						// e processamento do audio. Entretando, eventos antigos não possuem esse valor
						// e ainda usam "timeUsed"
						const data = doc.data();
						if (data.time_spend) {
							total += data.time_spend || 0;
						} else if (data.timeUsed) {
							total += data.timeUsed || 0;
						}
					});
					this.finalizedEventsTotal = total / 60000;
				});

			this.unsubscribe = eventsRef
				.where('start_date', '>=', this.startOfMonth().toString())
				.where('status', '==', 'open')
				.onSnapshot((querySnapshot) => {
					let total = 0;
					querySnapshot.forEach((doc) => {
						total += doc.data().time_spend || 0;
					});
					this.openEventsTotal = total / 60000;
				});
		},
	},
};
</script>

<style lang="scss" scoped>
.credits-display {
	display: flex;
	align-items: center;

	i {
		font-size: 1.2rem;
	}
}

.credits {
	background-color: #28264e;
	color: white;
	padding: 0.75rem;
	display: flex;
	align-items: center;
	height: calc(1.5em + 0.75rem + 2px);
}

.credits-left {
	border-radius: 1rem 0 0 1rem;
}

.credits-used {
	border-radius: 1rem;
}

.label {
	margin-left: 0.3rem;
	opacity: 0.8;
}

button {
	background-color: var(--primary);
	border-radius: 0 1rem 1rem 0;
}
</style>
