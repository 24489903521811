import Vue from 'vue';
import _ from 'lodash';

const state = {
	subtitles: [],
	sub_unsub: null,
	lastSubtitle: null,
	lastSubtitleUnsub: null,
	recentSubtitle: null,
	recentSubtitleUnsub: null,
	subtitlesListenerUnsubscribe: null,
};

const getters = {
	getSubtitles: (state) => {
		if (!state.subtitles || state.subtitles.length === 0) return [];
		return _.sortBy(state.subtitles, (sub) => sub.created_date.toDate());
	},
	getLastSubtitle: (state) => state.lastSubtitle,
	getRecentSubtitle: (state) => state.recentSubtitle,
};

const mutations = {
	setSubtitles: (state, subtitles) => {
		if (!subtitles || subtitles.length === 0) state.subtitles = [];
		state.subtitles = subtitles;
	},

	addSubtitle: (state, subtitle) => {
		if (!subtitle) return;

		state.subtitles.push(subtitle);
	},

	setSubtitlesUnsubscriber: (state, unsub) => (state.sub_unsub = unsub),

	setLastSubtitle: (state, subtitle) => {
		if (!subtitle) return;
		state.lastSubtitle = subtitle;
	},

	setRecentSubtitle: (state, subtitle) => {
		if (!subtitle) return;
		state.recentSubtitle = subtitle;
	},

	setLastSubtitleUnsub: (state, unsub) => (state.lastSubtitleUnsub = unsub),

	setRecentSubtitleUnsub: (state, unsub) => (state.recentSubtitleUnsub = unsub),

	clearLastSubtitle: (state) => (state.lastSubtitle = null),
	clearRecentSubtitle: (state) => (state.RecentSubtitle = null),
};

const actions = {
	async getSubtitles(_, { user_id, event_id }) {
		/**
		 * @type {firebase.default.firestore.Firestore}
		 */
		const db = Vue.firebase.firestore();

		const snapshot = await db.collection(`users/${user_id}/events/${event_id}/segments`).get();
		return snapshot.docs.map((doc) => ({
			id: doc.id,
			...doc.data(),
		}));
	},

	async startListeningSubtitles({ commit }, data) {
		commit('setSubtitles', []);

		const { user_id, event_id, app } = data;
		const firebaseApp = app || Vue.firebase;
		const db = firebaseApp.firestore();

		const collection_name = `users/${user_id}/events/${event_id}/segments`;
		const query = db.collection(collection_name).orderBy('created_date', 'desc').limit(10);

		const unsubscriber = query.onSnapshot((snapshot) => {
			snapshot.docChanges().forEach((change) => {
				const subtitle = { id: change.doc.id, ...change.doc.data() };

				if (change.type === 'added') {
					commit('addSubtitle', subtitle);
				}
				if (change.type === 'modified') {
					commit('updateSubtitle', subtitle);
				}
				if (change.type === 'removed') {
					commit('deleteSubtitle', subtitle);
				}
			});
		});

		commit('setSubtitlesUnsubscriber', unsubscriber);
	},

	stopListeningSubtitles({ state, commit }) {
		if (state.sub_unsub) state.sub_unsub();
		commit('setSubtitles', []);
	},

	async startListeningLastSubtitle({ commit }, data) {
		const { user_id, event_id, app } = data;

		const firebaseApp = app || Vue.firebase;
		const collection_name = `users/${user_id}/events/${event_id}/segments`;

		// return new Promise((resolve, reject) => {
		/** @type {firebase.default.firestore.Firestore} */
		const db = firebaseApp.firestore();

		const query = db.collection(collection_name).orderBy('created_date', 'desc').limit(1);
		const unsub = query.onSnapshot((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				commit('setLastSubtitle', { id: doc.id, ...doc.data() });
			});
		});
		commit('setLastSubtitleUnsub', unsub);

		// });
	},

	stopListeningLastSubtitle({ state, commit }) {
		if (state.lastSubtitleUnsub) state.lastSubtitleUnsub();
		commit('clearLastSubtitle');
	},

	async startListeningRecentSubtitle({ commit }, data) {
		const { user_id, event_id, app } = data;

		const firebaseApp = app || Vue.firebase;
		const collection_name = `users/${user_id}/events/${event_id}/segments`;

		// return new Promise((resolve, reject) => {
		/** @type {firebase.default.firestore.Firestore} */
		const db = firebaseApp.firestore();

		const query = db.collection(collection_name).orderBy('created_date', 'desc').limit(10);
		const unsub = query.onSnapshot((querySnapshot) => {
			const subtitles = [];
			querySnapshot.forEach((doc) => {
				subtitles.push({ id: doc.id, ...doc.data() });
			});
			commit('setRecentSubtitle', subtitles);
		});
		commit('setRecentSubtitleUnsub', unsub);

		// });
	},

	stopListeningRecentSubtitle({ state, commit }) {
		if (state.recentSubtitleUnsub) state.recentSubtitleUnsub();
		commit('clearRecentSubtitle');
	},
};

export default {
	state,
	getters,
	mutations,
	actions,
	namespaced: true,
};
