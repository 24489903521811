<template>
	<div>
		<b-alert
			:show="dismissCountDown"
			fade
			variant="danger"
			@dismissed="dismissCountDown = 0"
			@dismiss-count-down="countDownChanged"
		>
			{{ message }}
		</b-alert>
		<form action="#" role="form" class="px-md-4 px-lg-5 px-xl-4" @submit.prevent="tryLogin">
			<div class="form-group">
				<label for="email" aria-label="Email">{{ $t('email') }}</label>
				<input
					id="email"
					ref="emailInput"
					v-model="form.email"
					v-focus
					type="email"
					class="form-control"
					autocomplete="email"
				/>
			</div>

			<div class="form-group">
				<label for="pwd" aria-label="Senha">{{ $t('password') }}</label>
				<input id="pwd" v-model="form.password" type="password" class="form-control" autocomplete="current-password" />
			</div>

			<div class="form-group">
				<b-form-checkbox v-model="form.persistence"> {{ $t('keepLogged') }} </b-form-checkbox>
			</div>

			<div class="form-group">
				<button id="LoginBtn" type="submit" class="btn btn-pink btn-block btn-lg rounded rounded-pill text-uppercase">
					{{ $t('signIn') }}
				</button>
			</div>
		</form>
	</div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('user');

export default {
	name: 'LoginForm',
	data() {
		return {
			form: {
				email: '',
				password: '',
				persistence: true,
			},
			dismissSecs: 3,
			dismissCountDown: 0,
			message: '',
		};
	},

	methods: {
		tryLogin() {
			const loader = this.$loading.show();

			this.login(this.form).then(this.loginSucceded).catch(this.showError).finally(loader.hide);
		},

		loginSucceded() {
			//console.log('🚀 ~ loginSucceded ~ loginSucceded');
			this.$router.push('/dashboard');
		},

		countDownChanged(dismissCountDown) {
			this.dismissCountDown = dismissCountDown;
		},

		showError(message) {
			this.dismissCountDown = this.dismissSecs;
			console.error(message);
			this.message = this.$t('invalidEmailOrPassword');
		},

		...mapActions(['login']),
	},
};
</script>
