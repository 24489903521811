<template>
	<b-container>
		<div class="row">
			<h4 class="text-center">{{ $t('payments.insertCardInfo') }}</h4>
		</div>
		<div class="row">
			<form-input :errors="formErrors['creditCard.number']">
				<label for="credCard">{{ $t('payments.cardNumber') }}</label>
				<the-mask
					id="credCard"
					v-model="localCreditCard.number"
					class="form-control"
					:mask="['#### #### #### ####']"
					placeholder="0000 0000 0000 0000"
					@input="clearErrors('creditCard.number')"
				></the-mask>
			</form-input>
		</div>
		<div class="row">
			<form-input :errors="formErrors['creditCard.holderName']">
				<label for="nameCardCred">{{ $t('payments.nameHolder') }}</label>
				<b-form-input
					id="nameCardCred"
					v-model="localCreditCard.holderName"
					type="text"
					:placeholder="$t('payments.nameHolder')"
					@input="clearErrors('creditCard.holderName')"
				></b-form-input>
			</form-input>
		</div>

		<div class="row row-flex">
			<div class="row-flex">
				<form-input :errors="formErrors['creditCard.expMonth']">
					<label for="mes">{{ $t('month') }}</label>
					<b-form-select
						id="mes"
						v-model="localCreditCard.expMonth"
						type="text"
						:options="months"
						@input="clearErrors('creditCard.expMonth')"
					></b-form-select>
				</form-input>
				<form-input :errors="formErrors['creditCard.expYear']">
					<label for="ano">{{ $t('year') }}</label>
					<b-form-select
						id="ano"
						v-model="localCreditCard.expYear"
						type="text"
						:options="years"
						@input="clearErrors('creditCard.expYear')"
					></b-form-select>
				</form-input>
			</div>

			<form-input :errors="formErrors['creditCard.cVV']">
				<label for="codVerificacao"
					>{{ $t('payments.cvv') }} <i id="cvv-info" class="fa-solid fa-circle-info"></i
				></label>
				<b-tooltip target="cvv-info" triggers="hover"> {{ $t('payments.cvvInfo') }} </b-tooltip>
				<the-mask
					id="codVerificacao"
					v-model="localCreditCard.cvv"
					class="form-control"
					:mask="['###', '####']"
					placeholder="ccv"
					@input="clearErrors('creditCard.cVV')"
				></the-mask>
			</form-input>
		</div>

		<div class="row row-small">
			<form-input :errors="formErrors['customer.documentType']">
				<label for="tipo-document">{{ $t('payments.typeOfDocument') }}</label>
				<b-form-select
					id="tipo-documento"
					v-model="localUserDocument.documentType"
					:options="documentOptions"
					@input="clearErrors('billingAddress.state')"
				></b-form-select>
			</form-input>
			<div>
				<form-input :errors="formErrors['customer.document']">
					<label for="tipo-document">{{ $t('document') }}</label>
					<the-mask
						id="documento"
						v-model="localUserDocument.document"
						class="form-control"
						:mask="documentMask"
						:placeholder="documentPlaceholder"
						@input="clearErrors('customer.document')"
					></the-mask>
				</form-input>
			</div>
		</div>

		<div class="mt-5 row">
			<h4 class="text-center">{{ $t('payments.insertBillingAddressInfo') }}</h4>
		</div>
		<div class="row">
			<form-input :errors="formErrors['billingAddress.zipCode']">
				<label for="CEP">{{ $t('zipCode') }}</label>
				<the-mask
					id="CEP"
					v-model="localBillingAddress.zipCode"
					class="form-control"
					:mask="zipMask"
					:placeholder="zipPlaceholder"
					@input="clearErrors('billingAddress.zipCode'), verifyCEP()"
				></the-mask>
			</form-input>
		</div>
		<div class="row row-flex">
			<form-input :errors="formErrors['billingAddress.country']">
				<label for="pais">{{ $t('country') }}</label>
				<b-form-select
					id="pais"
					v-model="localBillingAddress.country"
					:options="countryOptions"
					@input="clearErrors('billingAddress.country')"
					@change="fetchAdminDivisionOptions()"
				></b-form-select>
			</form-input>
			<form-input :errors="formErrors['billingAddress.state']">
				<label for="estado">{{ $t('state') }}</label
				><b-form-select
					id="estado"
					v-model="localBillingAddress.state"
					:options="adminDivisionOptions"
					@input="clearErrors('billingAddress.state')"
				></b-form-select>
			</form-input>
		</div>
		<div class="row row-flex">
			<form-input :errors="formErrors['billingAddress.street']">
				<label for="endereco">{{ $t('payments.address') }}</label>
				<b-form-input
					id="endereco"
					v-model="localBillingAddress.street"
					type="text"
					@input="clearErrors('billingAddress.street')"
				></b-form-input>
			</form-input>
			<form-input :errors="formErrors['billingAddress.placeNumber']">
				<label for="numero">{{ $t('payments.placeNumber') }}</label
				><b-form-input
					id="numero"
					v-model="localBillingAddress.placeNumber"
					type="number"
					@input="clearErrors('billingAddress.placeNumber')"
				></b-form-input>
			</form-input>
		</div>
		<div class="row row-flex">
			<form-input :errors="formErrors['billingAddress.neighborhood']">
				<label for="bairro">Bairro</label
				><b-form-input
					id="bairro"
					v-model="localBillingAddress.neighborhood"
					type="text"
					@input="clearErrors('billingAddress.neighborhood')"
				></b-form-input>
			</form-input>
			<form-input :errors="formErrors['billingAddress.city']">
				<label for="cidade">{{ $t('city') }}</label
				><b-form-input
					id="cidade"
					v-model="localBillingAddress.city"
					type="text"
					@input="clearErrors('billingAddress.city')"
				></b-form-input>
			</form-input>
		</div>

		<div class="row">
			<p class="text-center">{{ $t('payments.youWillConfirmThePurchase') }}</p>
			<p v-if="formErrorCount > 0" class="text-red text-center">{{ $t('payments.formErrors') }}</p>
			<p v-if="globalErrorCount > 0" class="text-red text-center">
				{{ $t('payments.cardCheckError') }}
			</p>
		</div>
		<CreditsModalButtons
			:can-continue="formErrorCount + globalErrorCount == 0"
			@step-forward="verifyData()"
			@step-backward="$emit('step-backward')"
		>
			<template #backward>
				<i class="mr-1 fas fa-angle-double-left"></i>
				<strong>{{ $t('back') }}</strong>
			</template>
			<template #forward>
				<template v-if="!verifyingData">
					<strong>{{ $t('next') }}</strong>
					<i class="ml-1 fas fa-angle-double-right"></i>
				</template>
				<template v-if="verifyingData">
					<i class="fas fa-circle-notch fa-spin"></i>
				</template>
			</template>
		</CreditsModalButtons>
	</b-container>
</template>

<script>
import { AllMonths } from '@/util/months';
import { AllYears } from '@/util/years';
import CreditsModalButtons from './CreditsModalButtons.vue';
import CreditsModalCardInfoForm from './CreditsModalCardInfoForm';
import { TheMask } from 'vue-the-mask';
import { isValidCreditCard, isValidDocument } from '@/util/validators';
import Vue from 'vue';

export default {
	components: {
		CreditsModalButtons,
		TheMask,
		FormInput: CreditsModalCardInfoForm,
	},

	props: {
		userDocument: {
			type: Object,
			required: true,
		},
		creditCardInfo: {
			type: Object,
			required: true,
		},
		billingAddress: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			localUserDocument: this.userDocument,
			localCreditCard: { ...this.creditCardInfo },
			localBillingAddress: { ...this.billingAddress },
			countryOptions: [{ value: 'BR', text: 'Brasil' }],
			countryData: [],
			adminDivisionOptions: [],
			months: AllMonths,
			years: AllYears,
			verifyingData: false,
			documentOptions: [
				{ value: 'cpf', text: 'CPF' },
				{ value: 'cnpj', text: 'CNPJ' },
				{ value: 'passport', text: 'Passporte' },
			],

			// We need all errors so Vue can track the object
			formErrors: {
				'creditCard.number': [],
				'creditCard.holderName': [],
				'creditCard.expMonth': [],
				'creditCard.expYear': [],
				'creditCard.cVV': [],

				'customer.document': [],
				'customer.documentType': [],

				'billingAddress.zipCode': [],
				'billingAddress.country': [],
				'billingAddress.state': [],
				'billingAddress.street': [],
				'billingAddress.placeNumber': [],
				'billingAddress.neighborhood': [],
				'billingAddress.city': [],
			},
			// For any other error that is not related to a field use this array
			globalErrors: [],
		};
	},

	computed: {
		formErrorCount() {
			return Object.values(this.formErrors).reduce((accumulator, errorList) => {
				return accumulator + errorList.length;
			}, 0);
		},
		globalErrorCount() {
			return this.globalErrors.length;
		},
		documentMask() {
			switch (this.localUserDocument.documentType) {
				case 'cpf':
					return '###.###.###-##';
				case 'cnpj':
					return '##.###.###/####-##';
				default:
					return '#########';
			}
		},
		zipMask() {
			if (this.localBillingAddress.country === 'BR') {
				return '#####-###';
			}
			return 'X'.repeat(20);
		},
		zipPlaceholder() {
			if (this.localBillingAddress.country === 'BR') {
				return '00000-000';
			}
			return '';
		},
		documentPlaceholder() {
			switch (this.localUserDocument.documentType) {
				case 'cpf':
					return '000.000.000-00';
				case 'cnpj':
					return '00.000.000/0000-00';
				default:
					return '';
			}
		},
	},

	created() {
		this.fetchCountryOptions();
	},

	methods: {
		async fetchCountryOptions() {
			const functions = Vue.firebase.functions();
			const fetchCountryOptions = functions.httpsCallable('fetchCountryOptions');
			const countries = await fetchCountryOptions({ lang: 'pt' });
			// We need to save the full data to find the geonameId, needed to fetch the admin divisions
			this.countryData = countries.data.geonames;

			this.countryOptions = countries.data.geonames
				.sort(({ countryName: a }, { countryName: b }) => a.localeCompare(b))
				.map((country) => {
					return { text: country.countryName, value: country.countryCode };
				});
			this.fetchAdminDivisionOptions();
		},

		async fetchAdminDivisionOptions() {
			const geonameId = this.countryData.find(
				(country) => country.countryCode === this.localBillingAddress.country
			).geonameId;
			const functions = Vue.firebase.functions();
			const fetchAdminDivisionOptions = functions.httpsCallable('fetchAdminDivisionOptions');
			const divisions = await fetchAdminDivisionOptions({ geonameId, lang: 'pt' });
			this.adminDivisionOptions = divisions.data.geonames
				.sort(({ adminName1: a }, { adminName1: b }) => a.localeCompare(b))
				.map((division) => {
					return { text: division.adminName1, value: division.adminCodes1.ISO3166_2 };
				});
		},

		clearErrors(key) {
			this.formErrors[key] = [];
		},

		addError(key, error) {
			this.formErrors[key].push(error);
		},
		verifyCEP() {
			if (this.localBillingAddress.country !== 'BR') return;

			const cep = this.localBillingAddress.zipCode; // Número do CEP desejado
			if (cep.length < 8) return;
			const url = `https://viacep.com.br/ws/${cep}/json/`;

			fetch(url)
				.then((response) => response.json())
				.then((data) => {
					this.localBillingAddress.street = data.logradouro;
					this.localBillingAddress.neighborhood = data.bairro;
					this.localBillingAddress.city = data.localidade;
					this.localBillingAddress.state = data.uf.toUpperCase();
					if (data) this.localBillingAddress.country = 'BR';
				})
				.catch((error) => console.log(error));
		},
		validateInputNotEmpty(input, inputErrorKey) {
			if (input.trim() === '') {
				this.formErrors[inputErrorKey].push('Campo obrigatório');
			}
		},

		validateDataLocally() {
			this.validateInputNotEmpty(this.localCreditCard.holderName, 'creditCard.holderName');
			this.validateInputNotEmpty(this.localCreditCard.expMonth, 'creditCard.expMonth');
			this.validateInputNotEmpty(this.localCreditCard.expYear, 'creditCard.expYear');
			this.validateInputNotEmpty(this.localCreditCard.cvv, 'creditCard.cVV');

			this.validateInputNotEmpty(this.localBillingAddress.zipCode, 'billingAddress.zipCode');
			this.validateInputNotEmpty(this.localBillingAddress.country, 'billingAddress.country');
			this.validateInputNotEmpty(this.localBillingAddress.state, 'billingAddress.state');
			this.validateInputNotEmpty(this.localBillingAddress.street, 'billingAddress.street');
			this.validateInputNotEmpty(this.localBillingAddress.placeNumber, 'billingAddress.placeNumber');
			this.validateInputNotEmpty(this.localBillingAddress.neighborhood, 'billingAddress.neighborhood');
			this.validateInputNotEmpty(this.localBillingAddress.city, 'billingAddress.city');

			if (!isValidCreditCard(this.localCreditCard.number)) {
				this.addError('creditCard.number', 'Cartão de Crédito Inválido');
			}

			if (this.localUserDocument.documentType === 'passport' && this.localBillingAddress.country === 'Brasil') {
				this.addError(
					'customer.documentType',
					'Somente pagamentos com endereço de cobrança fora do Brasil podem user passaporte como documento'
				);
			}

			if (this.localUserDocument.documentType !== 'passport' && !isValidDocument(this.localUserDocument.document)) {
				this.addError('customer.document', 'Documento Inválido');
			}
		},

		async verifyCreditCard() {
			const functions = Vue.firebase.functions();
			const checkCreditCard = functions.httpsCallable('payments-checkCreditCard');

			const payload = {
				creditCard: this.localCreditCard,
				billingAddress: this.localBillingAddress,
			};

			return await checkCreditCard(payload);
		},

		async verifyData() {
			this.verifyingData = true;
			this.validateDataLocally();
			if (this.formErrorCount > 0) {
				this.verifyingData = false;
				return;
			}

			try {
				const { data: response } = await this.verifyCreditCard();

				if (response.errors) {
					console.log(response.errors);
					Object.entries(response.errors[0]).forEach((entry) => {
						const [key, value] = entry;
						if (key in this.formErrors) {
							this.formErrors[key] = value;
						} else {
							this.globalErrors.push(value);
						}
					});

					this.verifyingData = false;
					return;
				}

				this.$emit('user-document-change', this.localUserDocument);
				this.$emit('credit-card-change', this.localCreditCard);
				this.$emit('billing-address-change', this.localBillingAddress);
				this.$emit('encrypted-card-change', response);
				this.$emit('step-forward');
			} catch (e) {
				console.error(e);
				this.globalErrors.push('Houve algum erro na verificação do cartão');
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.row {
	margin-inline: 0;
	display: block;
	margin-top: 0.8rem;
}

.row-flex {
	display: grid;
	gap: 1rem;
	grid-template-columns: 1fr 1fr;
}

.row-small {
	display: grid;
	gap: 1rem;
	grid-template-columns: 1fr 3fr;
}

label {
	margin-left: 0.2rem;
	padding: 0;
	font-weight: 600;
}

p {
	margin-left: 0.2rem;
}

input,
select {
	font-size: 18px;
	border-color: var(--border-color, #ced4da);
}

input::placeholder {
	font-style: italic;
	opacity: 0.7;
}
</style>
