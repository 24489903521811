<template>
	<div class="modal-buttons">
		<b-button
			v-if="$slots.backward"
			class="modal-button"
			variant="outline-secondary"
			size="lg"
			@click.prevent="$emit('step-backward')"
		>
			<slot name="backward" />
		</b-button>
		<b-button
			:disabled="!canContinue"
			class="modal-button"
			variant="pink"
			size="lg"
			@click.prevent="$emit('step-forward')"
		>
			<slot name="forward" />
		</b-button>
	</div>
</template>

<script>
export default {
	props: {
		canContinue: {
			type: Boolean,
			default: true,
		},
	},
};
</script>

<style scoped lang="scss">
.modal-buttons {
	display: flex;
	gap: 1rem;
	justify-content: center;
	margin-top: 2rem;
}

.modal-button {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.2rem;
	line-height: 1;
	padding-block: 1rem;
	width: 140px;
}
</style>
