<template>
	<b-modal
		v-model="isAddCreditsVisible"
		size="lg"
		:title="$t('payments.addMinutesButton')"
		scrollable
		hide-footer
		class="p-0 m-0 modal"
		@hide="handleHide"
		@hidden="clear"
	>
		<b-container class="mb-4">
			<b-row>
				<b-col>
					<b-progress height="0.5rem" variant="primary" :value="currentStep - 1" :max="numSteps - 1"></b-progress>
				</b-col>
			</b-row>
		</b-container>
		<credits-modal-select-credits
			v-if="currentStep == 1"
			:initial-value="creditsOnCart"
			@amount-change="setCreditsOnCart"
			@step-forward="stepForward"
			@step-backward="stepBackward"
		/>
		<credits-modal-card-info
			v-if="currentStep == 2"
			:user-document="userDocument"
			:credit-card-info="creditCardInfo"
			:billing-address="billingAddress"
			@user-document-change="userDocumentChange"
			@credit-card-change="creditCardChange"
			@billing-address-change="billingAddressChange"
			@encrypted-card-change="encryptedCardChange"
			@step-forward="stepForward"
			@step-backward="stepBackward"
		/>
		<credits-modal-pay-confirmation
			v-if="currentStep == 3"
			:credits-to-buy="creditsOnCart"
			:encrypted-card="encryptedCard"
			:save-card="saveCard"
			@save-card-change="saveCardChange"
			@step-forward="stepForward"
			@step-backward="stepBackward"
		/>
		<credits-modal-request-confirmation
			v-if="currentStep == 4"
			:credits-to-buy="creditsOnCart"
			:credit-card-info="creditCardInfo"
			:billing-address="billingAddress"
			:user-document="userDocument"
			:encrypted-card="encryptedCard"
			@step-forward="stepForward"
			@step-backward="stepBackward"
			@set-prevent-close="setPreventClose"
		/>
	</b-modal>
</template>

<script>
import CreditsModalSelectCredits from './CreditsModalSelectCredits.vue';
import CreditsModalCardInfo from './CreditsModalCardInfo.vue';
import CreditsModalPayConfirmation from './CreditsModalPayConfirmation.vue';
import CreditsModalRequestConfirmation from './CreditsModalRequestConfirmation.vue';

const initialValues = {
	currentStep: 1,
	creditsOnCart: 0,
	userDocument: {
		documentType: 'cpf',
		document: '',
	},
	creditCardInfo: {
		number: '',
		holderName: '',
		expMonth: '',
		expYear: '',
		cvv: '',
	},
	billingAddress: {
		street: '',
		placeNumber: '',
		neighborhood: '',
		city: '',
		state: '',
		country: 'BR',
		zipCode: '',
	},
	encryptedCard: {
		brand: '',
		cardId: '',
		lastFourDigits: '',
		expirationMonth: '',
		expirationYear: '',
	},
	saveCard: true,
};

export default {
	components: {
		CreditsModalSelectCredits,
		CreditsModalCardInfo,
		CreditsModalPayConfirmation,
		CreditsModalRequestConfirmation,
	},
	data() {
		return {
			...initialValues,
			numSteps: 4,
			preventClose: false,
		};
	},

	computed: {
		isAddCreditsVisible: {
			get: function () {
				return this.$store.getters.isAddCreditsVisible;
			},
			set: function () {
				this.$store.commit('setAddCreditsModalVisibility', false);
			},
		},
	},

	methods: {
		clear() {
			this.currentStep = initialValues.currentStep;
			this.creditsOnCart = initialValues.creditsOnCart;
			this.creditCardInfo = initialValues.creditCardInfo;
			this.billingAddress = initialValues.billingAddress;
			this.userDocument = initialValues.userDocument;
		},
		stepForward() {
			if (this.currentStep == this.numSteps) {
				this.$store.commit('setAddCreditsModalVisibility', false);
				return;
			}
			this.currentStep++;
		},
		stepBackward() {
			if (this.currentStep == 1) {
				this.$store.commit('setAddCreditsModalVisibility', false);
				return;
			}

			this.currentStep--;
		},
		setCreditsOnCart(value) {
			this.creditsOnCart = value;
		},

		creditCardChange(value) {
			this.creditCardInfo = value;
		},

		billingAddressChange(value) {
			this.billingAddress = value;
		},

		encryptedCardChange(value) {
			this.encryptedCard = value;
		},

		userDocumentChange(value) {
			this.userDocument = value;
		},

		saveCardChange(value) {
			this.saveCard = value;
		},

		handleHide(bvModalEvent) {
			if (this.preventClose) {
				bvModalEvent.preventDefault();
			}

			if (bvModalEvent.trigger == 'backdrop') {
				bvModalEvent.preventDefault();
			}
		},
		setPreventClose(value) {
			this.preventClose = value;
		},
	},
};
</script>

<style scoped lang="scss">
.footer-buttons {
	display: flex;
	justify-content: center;
	gap: 1rem;
}
</style>
