<template>
	<div id="LoginPage">
		<div class="m-3" style="position: absolute">
			<language-switcher />
		</div>
		<div class="container">
			<div class="row no-gutters align-items-center justify-content-center vh-100">
				<div class="col col-xs-8 col-xxl-6">
					<card-glass custom-class="shadow">
						<div class="row row-cols-1 align-items-center">
							<div class="col col-xl-6 order-2 order-xl-1">
								<div class="text-center mt-3 mb-4">
									<!-- <h2 class="text-uppercase">{{ $t('liveClosedCaptions') }}</h2> -->
								</div>
								<transition name="slide" mode="out-in">
									<login-form v-if="formVisible === 'login'" />
									<forgot-password-form v-else-if="formVisible === 'forgotPassword'" />
									<password-reset-form
										v-else-if="formVisible === 'resetPassword'"
										:action-code="actionCode"
										:email="email"
										@success="formVisible = 'login'"
									/>
									<register-user v-else-if="formVisible === 'registerUser'"></register-user>
								</transition>

								<div
									v-if="formVisible !== 'registerUser' && formVisible === 'login' && isRegistrationEnabled"
									class="form-group text-center font-weight-bold"
								>
									<a href="#" class="text-light" @click.prevent="registerUser">
										{{ $t('login.registerUser') }}
									</a>
								</div>

								<div v-if="formVisible !== 'resetPassword'" class="form-group text-center">
									<a href="#" class="text-light" @click.prevent="forgotPasswd">
										{{ formVisible === 'login' ? $t('login.forgotPassword') : $t('back') }}
									</a>
								</div>
							</div>
							<div class="col col-xl-6 order-1 order-xl-2">
								<!-- <img src="@/assets/images/skylar-logo-light-full.png" alt="logo" class="img-fluid" /> -->
								<img src="../assets/images/legenda-live-logo-white.png" alt="logo" class="img-fluid" />
							</div>
						</div>
					</card-glass>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import LanguageSwitcher from '../components/LanguageSwitcher.vue';
import ForgotPasswordForm from '../components/Login/ForgotPasswordForm.vue';
import LoginForm from '../components/Login/LoginForm.vue';
import PasswordResetForm from '../components/Login/PasswordResetForm.vue';
import RegisterUser from '../components/Login/RegisterUser.vue';
import { mapGetters } from 'vuex';

export default {
	name: 'Login',
	components: { LoginForm, ForgotPasswordForm, PasswordResetForm, LanguageSwitcher, RegisterUser },

	props: {
		resetPassword: {
			type: Boolean,
			default: false,
		},
		actionCode: {
			type: String,
			default: '',
		},
		email: {
			type: String,
			default: '',
		},
	},

	data() {
		return {
			formVisible: 'login',
		};
	},

	computed: {
		...mapGetters({
			isRegistrationEnabled: 'featureFlags/isRegistrationEnabled',
		}),
	},

	beforeMount() {
		if (this.resetPassword) {
			if (!this.email || !this.actionCode) {
				this.$router.push('/notFound');
			}
			this.formVisible = 'resetPassword';
		}
	},

	methods: {
		forgotPasswd() {
			this.formVisible = this.formVisible === 'login' ? 'forgotPassword' : 'login';
		},
		registerUser() {
			this.formVisible = 'registerUser';
		},
	},
};
</script>

<style scoped>
.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
	transition-duration: 0.25s;
	transition-property: height, opacity, transform;
	transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
	overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
	opacity: 0;
	transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
	opacity: 0;
	transform: translate(-2em, 0);
}
</style>
