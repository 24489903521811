import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/analytics';
import 'firebase/storage';

// Import the Firebase configurations
import firebaseConfigDev from './firebaseConfig.dev'; // For development
import firebaseConfigProd from './firebaseConfig.prod'; // For production

// Determine which configuration to use based on NODE_ENV
const firebaseConfig = process.env.NODE_ENV === 'production' ? firebaseConfigProd : firebaseConfigDev;

// eslint-disable-next-line no-unused-vars
const useEmulator = function (firebaseApp) {
	if (process.env.VUE_APP_USE_EMULATORS.includes('firestore')) firebaseApp.firestore().useEmulator('localhost', 8081);
	if (process.env.VUE_APP_USE_EMULATORS.includes('functions')) firebaseApp.functions().useEmulator('localhost', 5001);
	if (process.env.VUE_APP_USE_EMULATORS.includes('auth')) firebaseApp.auth().useEmulator('http://localhost:9099');
};

const install = function (Vue) {
	// Initialize Firebase
	firebase.initializeApp(firebaseConfig);
	firebase.analytics();

	const guestApp = firebase.initializeApp(firebaseConfig, '[GUEST_APP]');

	Vue.firebase = firebase;
	Vue.guestApp = guestApp;

	if (process.env.VUE_APP_USE_EMULATORS) {
		console.info('Running with firebase emulators: ', process.env.VUE_APP_USE_EMULATORS);
		useEmulator(firebase);
		useEmulator(guestApp);
	}
};

export default { install };
