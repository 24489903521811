<template>
	<b-dropdown-text class="notification-item" style="width: 420px">
		<span class="notification-body" :data-read="read">
			<i class="notification-icon" :class="icon" :data-color="color"></i>
			<span class="notification-text">
				<router-link :to="link || '#'" @click.native="handleNavigate">
					<span class="notification-title">{{ title }}</span
					><br />
					{{ body }}
					<br />
					<span class="notification-time">
						{{ formatTime(time) }}
					</span>
				</router-link>
			</span>
		</span>
		<button class="notification-delete" @click="deleteNotification">
			<i class="fa-solid fa-xmark"></i>
		</button>
	</b-dropdown-text>
</template>

<script>
import formatTime from '@/util/formatTime';
import Vue from 'vue';

export default {
	props: {
		title: {
			type: String,
			required: true,
		},
		body: {
			type: String,
			default: null,
		},
		icon: {
			type: String,
			default: 'fa-solid fa-info-circle',
		},
		color: {
			type: String,
			default: 'blue',
		},
		link: {
			type: String,
			default: null,
		},
		time: {
			type: Number,
			required: true,
		},
		read: {
			type: Boolean,
			required: true,
		},
		notificationId: {
			type: String,
			required: true,
		},
	},
	computed: {
		getUserId() {
			return this.$store.getters['user/getUserId'];
		},
	},
	methods: {
		formatTime,
		handleNavigate() {
			this.$store.commit('closeDropdown');
		},
		async deleteNotification() {
			const id = this.notificationId;

			const db = Vue.firebase.firestore();
			const notificationRef = db.doc(`users/${this.getUserId}/notifications/${id}`);
			try {
				await notificationRef.update({ deleted: true });
			} catch (error) {
				console.error(`Erro ao apagar notificação ${id}:`, error);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.notification-item {
	transition: all 0.4s;
	height: 140px;

	p {
		height: 100%;
		padding: 0;
		display: flex;
		color: #303030;
		font-weight: 300;
		position: relative;
		transition: opacity 0.1s, background-color 0.1s;
	}

	.notification-body {
		position: relative;
		display: flex;
		align-items: center;
		flex-grow: 1;

		&[data-read] {
			opacity: 0.85;
		}

		&:not([data-read]) .notification-title::after {
			display: inline-block;
			content: '';
			height: 4px;
			width: 4px;
			top: 50%;
			right: -0.75rem;
			aspect-ratio: 1;
			background-color: var(--primary);
			box-shadow: 0px 0px 4px 0px var(--primary);
			position: absolute;
			border-radius: 100%;
		}
	}

	.notification-text {
		display: block;
		padding: 1rem 1.5rem;
		flex-grow: 1;
	}

	.notification-title {
		font-size: 1.1rem;
		margin-bottom: 0.4rem;
		font-weight: 500;
		position: relative;
	}

	.notification-icon {
		font-size: 1.75rem;
		opacity: 0.6;
		padding-left: 1rem;

		&[data-color='red'] {
			color: var(--danger);
		}
		&[data-color='blue'] {
			color: var(--primary);
		}
		&[data-color='yellow'] {
			color: var(--warning);
		}
		&[data-color='green'] {
			color: var(--success);
		}
	}

	.notification-delete {
		background: none;
		border: none;
		opacity: 0;
		font-size: 1.4rem;
		padding-right: 1rem;
	}

	.notification-time {
		display: inline-block;
		margin-top: 0.4rem;
		font-size: 0.9rem;
		opacity: 0.7;
		position: relative;
	}

	a {
		color: inherit;
		flex-grow: 1;

		&:hover {
			text-decoration: none;
			color: inherit;
		}
	}

	a::before {
		content: '';
		position: absolute;
		inset: 0;
	}

	&:hover {
		background-color: #eee;

		.notification-delete {
			opacity: 0.2;
		}
	}
}
</style>
