import Login from '../views/Login.vue';
import NotFound from '../views/NotFound.vue';
import FinishSignup from '../views/FinishSignup.vue';

// lazy load views (more on https://router.vuejs.org/guide/advanced/lazy-loading.html)
const Dashboard = () => import('../views/Dashboard');
const UserProfile = () => import('../views/UserProfile.vue');
const Admin = () => import('../views/Admin.vue');
const AdminUser = () => import('../views/AdminUser.vue');
const Report = () => import('../views/Report.vue');
const Host = () => import(/* webpackChunkName: "host" */ '../views/Host.vue');
const Guest = () => import(/* webpackChunkName: "guest" */ '../views/Guest.vue');
const Projection = () => import('../views/Projection.vue');
const PictureInPicture = () => import('../views/PictureInPicture.vue');
const SrtGenerator = () => import('../views/SrtGenerator.vue');
const TwitchPlayer = () => import('../views/TwitchPlayer.vue');
const UserPayments = () => import('../views/UserPayments.vue');

// Middlewares
import auth from '../middleware/auth';
import loggedRedirection from '../middleware/loggedRedirection';
import emailActionHandler from '../middleware/emailActionHandler';

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Dashboard,
		redirect: '/dashboard',
		children: [
			{
				path: '/dashboard',
				name: 'Dashboard',
				component: Dashboard,
				meta: {
					middleware: auth,
				},
			},
		],
	},
	{
		path: '/admin',
		name: 'Admin',
		component: Admin,
		meta: {
			// TODO: permitir somente admins
			middleware: auth,
		},
	},
	{
		path: '/admin/user/:userId',
		name: 'AdminUser',
		component: AdminUser,
		props: true,
		meta: {
			// TODO: permitir somente admins
			middleware: auth,
		},
	},
	{
		path: '/live/a/:access_id',
		name: 'Guest',
		component: Guest,
		props: true,
	},
	{
		path: '/live/:event_id/:access_id',
		name: 'Host',
		component: Host,
		props: true,
		meta: {
			middleware: auth,
		},
	},
	{
		path: '/profile',
		name: 'UserProfile',
		component: UserProfile,
		meta: {
			middleware: auth,
		},
	},
	{
		path: '/payments',
		name: 'UserPayments',
		component: UserPayments,
		meta: {
			middleware: auth,
		},
	},
	{
		path: '/report',
		name: 'Report',
		component: Report,
		meta: {
			middleware: auth,
		},
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
		meta: {
			middleware: loggedRedirection,
		},
	},
	{
		path: '/auth/action',
		name: 'EmailActionHandler',
		meta: {
			middleware: emailActionHandler,
		},
	},
	{
		path: '/resetPassword',
		name: 'ResetPassword',
		component: Login,
		props: true,
		meta: {
			middleware: [loggedRedirection],
		},
	},
	{
		path: '/projection/a/:access_id',
		name: 'Projection',
		component: Projection,
		props: true,
	},
	{
		path: '/pip/a/:access_id',
		name: 'Picture-in-Picture',
		component: PictureInPicture,
		props: true,
	},
	{
		path: '/srtgen/uid/:user_id/event/:event_id',
		name: 'SrtGenerator',
		component: SrtGenerator,
		meta: {
			middleware: auth,
		},
	},
	{
		path: '/twitch/a/:access_id',
		name: 'Twitch',
		component: TwitchPlayer,
		props: true,
	},
	{
		path: '/finishSignup',
		name: 'FinishSignup',
		component: FinishSignup,
	},
	{ path: '*', component: NotFound },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
