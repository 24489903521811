<template>
	<div :class="['card', dark ? 'bg-glass-dark' : 'bg-glass', !noShadow ? '' : 'shadow-sm', customClass]">
		<div v-if="hasHeaderSlot" class="card-header">
			<slot name="header"></slot>
		</div>
		<div :class="['card-body text-light', bodyClass]">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		dark: {
			type: Boolean,
			default: false,
		},
		noShadow: {
			type: Boolean,
			default: false,
		},
		customClass: {
			type: String,
			default: '',
		},
		bodyClass: {
			type: String,
			default: '',
		},
	},

	computed: {
		hasHeaderSlot() {
			return !!this.$slots['header'];
		},
	},
};
</script>
