import { isWebUri } from 'valid-url';

export const atLeastOne = (object) => Object.values(object).some(Boolean);

export const atLeastOneSelected = (object) => Object.keys(object).some((key) => object[key].selected);

export const isValidURL = (value) => {
	if (!value) return true;
	const result = !!isWebUri(value);
	return result;
};

export function isValidCreditCard(numero) {
	// Remover espaços em branco
	if (!numero) return;
	numero = numero.replace(/\s/g, '');

	// Verificar se o número contém apenas dígitos
	if (!/^\d+$/.test(numero)) {
		return false;
	}

	// Verificar o comprimento do número do cartão
	if (numero.length < 13 || numero.length > 19) {
		return false;
	}

	// Aplicar o algoritmo de Luhn
	let soma = 0;
	let par = false;

	for (let i = numero.length - 1; i >= 0; i--) {
		let digito = parseInt(numero.charAt(i), 10);

		if (par) {
			digito *= 2;
			if (digito > 9) {
				digito -= 9;
			}
		}

		soma += digito;
		par = !par;
	}

	if (soma % 10 !== 0) {
		return false;
	}

	return true;
}

export function isValidDocument(numero) {
	numero = numero.replace(/\D/g, ''); // Remove caracteres não numéricos

	if (numero.length === 11) {
		// Validação de CPF
		let cpf = numero;
		let soma = 0;
		let resto;

		if (/^(\d)\1+$/.test(cpf)) {
			return false;
		}

		for (let i = 1; i <= 9; i++) {
			soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
		}

		resto = (soma * 10) % 11;

		if (resto === 10 || resto === 11) {
			resto = 0;
		}

		if (resto !== parseInt(cpf.substring(9, 10))) {
			return false;
		}

		soma = 0;

		for (let i = 1; i <= 10; i++) {
			soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
		}

		resto = (soma * 10) % 11;

		if (resto === 10 || resto === 11) {
			resto = 0;
		}

		if (resto !== parseInt(cpf.substring(10, 11))) {
			return false;
		}

		return true;
	} else if (numero.length === 14) {
		// Validação de CNPJ
		let cnpj = numero;
		let tamanho = cnpj.length - 2;
		let numeros = cnpj.substring(0, tamanho);
		let digitos = cnpj.substring(tamanho);
		let soma = 0;
		let pos = tamanho - 7;

		for (let i = tamanho; i >= 1; i--) {
			soma += parseInt(numeros.charAt(tamanho - i)) * pos--;

			if (pos < 2) {
				pos = 9;
			}
		}

		let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

		if (resultado !== parseInt(digitos.charAt(0))) {
			return false;
		}

		tamanho += 1;
		numeros = cnpj.substring(0, tamanho);
		soma = 0;
		pos = tamanho - 7;

		for (let i = tamanho; i >= 1; i--) {
			soma += parseInt(numeros.charAt(tamanho - i)) * pos--;

			if (pos < 2) {
				pos = 9;
			}
		}

		resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

		if (resultado !== parseInt(digitos.charAt(1))) {
			return false;
		}

		return true;
	} else {
		// Número inválido
		return false;
	}
}
