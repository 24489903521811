import Vue from 'vue';

const state = {
	// speakers (list)
	speakers: [],
	speakers_unsub: null,

	// speaker (single)
	speaker_live: null,
	speaker_live_unsub: null,
};

const getters = {
	// speakers (list)
	getSpeakers: (state) => state.speakers,

	// speaker (single)
	getSpeakerLive: (state) => state.speaker_live,
};

const mutations = {
	// speakers (list)
	addSpeaker: (state, data) => state.speakers.push(data),
	updateSpeaker: (state, speaker) => {
		const index = state.speakers.map((e) => e.id).indexOf(speaker.id);
		if (index > -1) {
			state.speakers.splice(index, 1, speaker);
		}
	},
	removeSpeaker: (state, speaker) => {
		const index = state.speakers.map((e) => e.id).indexOf(speaker.id);
		if (index > -1) {
			state.speakers.splice(index, 1);
		}
	},
	setSpeakersUnsubscriber: (state, unsub) => (state.speakers_unsub = unsub),
	clear: (state) => (state.speakers = []),

	// speaker (single)
	addLiveSpeaker: (state, data) => (state.speaker_live = data),
	updateLiveSpeaker: (state, speaker) => (state.speaker_live = speaker),
	removeLiveSpeaker: (state) => (state.speaker_live.status = 'inactive'),
	setSpeakerLiveUnsubscriber: (state, unsub) => (state.speaker_live_unsub = unsub),
	clearLiveSpeaker: (state) => (state.speaker_live = null),
};

const actions = {
	addSpeakers: function ({ rootState }, speaker_data) {
		const user_id = rootState.user.uid;
		const event_id = speaker_data.event_id;
		const speakers = speaker_data.guests;

		speakers.forEach(async (speakerData) => {
			await createSpeaker(user_id, event_id, speakerData);
		});

		return 'Speakers added';
	},

	addSingleSpeaker: function ({ rootState }, speaker_data) {
		const user_id = speaker_data.user_id || rootState.user.uid;
		const event_id = speaker_data.event_id;

		delete speaker_data.event_id;

		return new Promise((resolve, reject) => {
			createSpeaker(user_id, event_id, speaker_data)
				.then((result) => {
					resolve(result);
				})
				.catch((err) => {
					console.error(err);
					reject(err);
				});
		});
	},

	muteSpeaker: function ({ rootState }, speaker) {
		const user_id = speaker.user_id || rootState.user.uid;
		const event_id = speaker.event_id;
		const speaker_id = speaker.id;
		let bool_mute_status = !speaker.mute_status;

		return new Promise((resolve, reject) => {
			const firestore = Vue.firebase.firestore();
			firestore
				.doc(`users/${user_id}/events/${event_id}/speakers/${speaker_id}`)
				.update({
					mute_status: bool_mute_status,
				})
				.then(() => {
					resolve();
				})
				.catch((err) => {
					reject(err);
				});
		});
	},

	removeSpeaker: function ({ rootState, commit }, speaker) {
		//Remove speaker from store and change status to 'inactive'
		const { event_id, id } = speaker;
		const user_id = speaker.user_id || rootState.user.uid;

		return new Promise((resolve, reject) => {
			const finished_date = Date.now().toString();
			const db = Vue.firebase.firestore();
			db.doc(`users/${user_id}/events/${event_id}/speakers/${id}`)
				.set(
					{
						status: 'inactive',
						finished_date,
					},
					{ merge: true }
				)
				.then(() => {
					commit('removeSpeaker', speaker);
					resolve();
				})
				.catch((err) => {
					console.error(err);
					reject(err);
				});
		});
	},

	finalizeEventSpeakers: function ({ rootState, state }, event_id) {
		const user_id = rootState.user.uid;
		const db = Vue.firebase.firestore();
		const speakers = state.speakers;

		speakers.forEach(async (speaker) => {
			const batch = db.batch();

			// set the speaker access token as unavaiable
			const accessDoc = db.doc(`access/${speaker.access_id}`);
			batch.update(accessDoc, { status: 'unavailable' });

			// set this speaker as inactive
			const finished = Date.now().toString();
			const speakerDoc = db.doc(`users/${user_id}/events/${event_id}/speakers/${speaker.id}`);
			batch.set(
				speakerDoc,
				{
					finished_date: finished,
					status: 'inactive',
				},
				{ merge: true }
			);

			await batch.commit();
		});

		return 'Event finished for all speakers';
	},

	startListeningSpeakers: function ({ rootState, commit }, { user_id, event_id }) {
		commit('clear');

		return new Promise((resolve, reject) => {
			const db = Vue.firebase.firestore();

			// fetch all active speakers in this event
			const userId = user_id || rootState.user.uid;
			const collection_name = `users/${userId}/events/${event_id}/speakers`;
			const query = db.collection(collection_name).where('status', '==', 'active');

			const speakers_unsub = query.onSnapshot(
				(snapshot) => {
					snapshot.docChanges().forEach((change) => {
						const speaker = {
							id: change.doc.id,
							user_id: userId,
							event_id: event_id,
							...change.doc.data(),
						};

						if (change.type === 'added') {
							commit('addSpeaker', speaker);
						}
						if (change.type === 'modified') {
							commit('updateSpeaker', speaker);
						}
						if (change.type === 'removed') {
							commit('removeSpeaker', speaker);
						}
					});
					resolve();
				},
				(error) => {
					console.error('Firestore error!', error.message);
					reject('Firestore error! Check console log.');
				}
			);

			commit('setSpeakersUnsubscriber', speakers_unsub);
		});
	},

	stopListeningSpeakers: function ({ state, commit }) {
		// unsubscribe to cancel the live speaker listening
		if (state.speakers_unsub) state.speakers_unsub();
		commit('clear');
	},

	startListeningLiveSpeaker: async function ({ /*dispatch,*/ commit }, data) {
		commit('clearLiveSpeaker');

		const { user_id, event_id, speaker_id, app } = data;

		const firebaseApp = app || Vue.firebase;
		const db = firebaseApp.firestore();
		const speakerDoc = db.doc(`users/${user_id}/events/${event_id}/speakers/${speaker_id}`);

		return new Promise((resolve, reject) => {
			const speaker_live_unsub = speakerDoc.onSnapshot(
				(docSnapshot) => {
					if (docSnapshot.exists) {
						const speaker = {
							id: docSnapshot.id,
							...docSnapshot.data(),
						};
						commit('updateLiveSpeaker', speaker);
						resolve(speaker);
					} else {
						commit('removeLiveSpeaker');
						resolve();
					}
				},
				(error) => {
					console.error('Firestore error!', error.message);
					reject('Firestore error! Check console log.');
				}
			);

			commit('setSpeakerLiveUnsubscriber', speaker_live_unsub);
		});
	},

	stopListeningLiveSpeaker: function ({ state, commit }) {
		// unsubscribe to cancel the live speaker listening
		if (state.speaker_live_unsub) state.speaker_live_unsub();
		commit('clearLiveSpeaker');
	},
};

export default {
	state,
	getters,
	mutations,
	actions,
	namespaced: true,
};

/** Create a new speaker document in the event, with their access_id. */
async function createSpeaker(user_id, event_id, speaker_data) {
	const firestore = Vue.firebase.firestore();
	let batch = firestore.batch();

	// create a new speaker document ref
	// IMPORTANT: must get the newSpeakerRef before accessRef, to generate the document id used as speaker_id in accessRef
	const newSpeakerRef = firestore.collection(`users/${user_id}/events/${event_id}/speakers`).doc();

	// add an access document to this speaker
	const accessRef = firestore.collection('access').doc();
	batch.set(accessRef, {
		parent_id: user_id,
		event_id: event_id,
		speaker_id: newSpeakerRef.id,
		status: 'available',
		date: Vue.firebase.firestore.Timestamp.now(),
	});

	// add a new speaker in the event
	speaker_data.mute_status = false;
	speaker_data.status = 'active';
	speaker_data.started = Date.now().toString();
	speaker_data.access_id = accessRef.id;
	batch.set(newSpeakerRef, speaker_data);

	await batch.commit();
}
