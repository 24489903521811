import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import userModule from './modules/user';
import eventsModule from './modules/events';
import speakersModule from './modules/speakers';
import subtitlesModule from './modules/subtitles';
import guestModule from './modules/guest';
import featureFlagsModule from './modules/featureFlags';
import webSocketsModule from './modules/websockets';

export const createStore = () => {
	return new Vuex.Store({
		modules: {
			user: userModule,
			events: eventsModule,
			speakers: speakersModule,
			subtitles: subtitlesModule,
			guest: guestModule,
			featureFlags: featureFlagsModule,
			webSockets: webSocketsModule,
		},
		state: {
			navbarVisible: false,
			addCreditsVisible: false,
			creditsFormModalVisible: false,
			assistantVisible: false,
			locale: 'pt-BR',
			connectivityStatus: 'unknown',
			backgroundFooter: true,
			closeDropdown: null,
		},
		mutations: {
			setNavBarVisibility: (state, value) => (state.navbarVisible = value),
			setLocale: (state, locale) => (state.locale = locale),
			setConnectivityStatus: (state, status) => (state.connectivityStatus = status),
			setBackgroundFooter: (state, value) => (state.backgroundFooter = value),
			setAddCreditsModalVisibility: (state, value) => (state.addCreditsVisible = value),
			setAssistantVisibility: (state, value) => (state.assistantVisible = value),
			setFormModalVisibility: (state, value) => (state.creditsFormModalVisible = value),
			closeDropdown: (state) => (state.closeDropdown = Date.now()),
		},
		getters: {
			isNavBarVisible: (state) => state.navbarVisible,
			isAddCreditsVisible: (state) => state.addCreditsVisible,
			isAssistantVisible: (state) => state.assistantVisible,
			isCreditsFormModalVisible: (state) => state.creditsFormModalVisible,
			locale: (state) => state.locale,
			connectivityStatus: (state) => state.connectivityStatus,
			backgroundFooter: (state) => state.backgroundFooter,
			getCloseDropdown: (state) => state.closeDropdown,
		},
		actions: {
			async startListeningConnection({ commit }) {
				commit('setConnectivityStatus', navigator.onLine ? 'online' : 'offline');

				window.addEventListener('offline', () => {
					commit('setConnectivityStatus', 'offline');
				});

				window.addEventListener('online', async () => {
					commit('setConnectivityStatus', 'connected');

					let i = 0;
					while (i < 5) {
						try {
							const response = await fetch('/', {
								method: 'GET', // *GET, POST, PUT, DELETE, etc.
								mode: 'no-cors',
							});
							if (response.ok) {
								commit('setConnectivityStatus', 'online');
								return;
							}
						} catch (error) {
							console.warn('falhou na tentativa de conexao.', error);
						}
						i++;
					}

					commit('setConnectivityStatus', 'offline');
				});
			},
			setLocale({ commit }, locale) {
				if (locale.startsWith('en-') || locale == 'en') {
					commit('setLocale', 'en');
					return;
				}
				if (locale.startsWith('pt-') || locale == 'pt') {
					commit('setLocale', 'pt-BR');
					return;
				}
				if (locale.startsWith('es-') || locale == 'es') {
					commit('setLocale', 'es');
					return;
				}
				// If it doesn't start with en, pt or es, defaults to English
				commit('setLocale', 'en');
			},
		},
	});
};

export const store = createStore();
