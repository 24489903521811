import Vue from 'vue';

const state = {
	events: [],
	events_unsubscriber: null,
	event_info: '',
	event_actions: [],
	event_live_unsub: null,
	event_live: null,
	presetStreams: null,
	recentLanguages: [],
};

const getters = {
	events_data: (state) => state.events,

	current_event: (state) => state.event_info,

	/* live event */
	getLiveEvent: (state) => state.event_live,

	getLiveEventId: (state) => (state.event_live && state.event_live.id) || '',

	getPresetStreams: (state) => state.presetStreams,

	getRecentLanguages: (state) => state.recentLanguages,
};

const mutations = {
	addEvent: (state, event) => state.events.push(event),
	updateEvent: (state, event) => {
		const index = state.events.map((e) => e.id).indexOf(event.id);
		state.events.splice(index, 1, event);
	},
	deleteEvent: (state, event) => {
		const index = state.events.map((e) => e.id).indexOf(event.id);
		state.events.splice(index, 1);
	},
	clear: (state) => (state.events = []),

	setEventsUnsubscriber: (state, unsub) => (state.events_unsubscriber = unsub),

	getEventData: (state, data) => (state.event_info = data),

	setEventActions: (state, data) => (state.eventActions = data),

	addEventAction: (state, data) => state.event_actions.push(data),

	/* live event */
	updateLiveEvent: (state, event) => (state.event_live = event),
	removeLiveEvent: (state) => (state.event_live.status = 'inactive'),
	clearLiveEvent: (state) => (state.event_live = null),
	setEventLiveUnsubscriber: (state, unsub) => (state.event_live_unsub = unsub),

	setPresetStreams: (state, streams) => {
		if (state.presetStreams?.microphoneStream)
			state.presetStreams?.microphoneStream.getTracks().forEach((track) => track.stop());
		if (state.presetStreams?.sharedAudioStream)
			state.presetStreams?.sharedAudioStream.getTracks().forEach((track) => track.stop());

		state.presetStreams = streams || null;
	},

	setRecentLanguages: (state, list) => (state.recentLanguages = list),
};

const actions = {
	getAccess: async function (_, access_id) {
		const firestore = Vue.firebase.firestore;
		const snapshot = await firestore().collection('access').doc(`${access_id}`).get();
		const access = snapshot.data();
		if (!access) throw new Error('Access do not exists');

		if (access.status != 'available') {
			throw new Error('Access Denied');
		}

		return access;
	},

	getUserId: async function ({ rootState }) {
		return rootState.user.uid;
	},

	createNewEvent: async function ({ rootState }, eventData) {
		/**
		 * @type {firebase.default.functions.Functions}
		 */
		const functions = Vue.firebase.functions();
		const createEvent = functions.httpsCallable('createEvent');

		const user_id = rootState.user.uid;

		const { data } = await createEvent({
			user_id,
			eventData,
		});

		return data;
	},

	storeAccess: async function ({ rootState }, data) {
		const firestore = Vue.firebase.firestore;
		const user_id = rootState.user.uid;

		const { event_id, access_id } = data;

		await firestore().doc(`users/${user_id}/events/${event_id}`).set({ access_id }, { merge: true });
	},

	startListeningEventsData: function ({ commit, rootState }, { userId }) {
		commit('clear');

		const db = Vue.firebase.firestore();
		const user_id = userId || rootState.user.uid;
		const collection_name = `users/${user_id}/events`;
		const query = db.collection(collection_name);

		return new Promise((resolve, reject) => {
			const events_unsubscriber = query.onSnapshot(
				(snapshot) => {
					snapshot.docChanges().forEach((change) => {
						const event = {
							id: change.doc.id,
							user_id: user_id,
							...change.doc.data(),
						};

						if (change.type === 'added') {
							commit('addEvent', event);
						}
						if (change.type === 'modified') {
							commit('updateEvent', event);
						}
						if (change.type === 'removed') {
							commit('deleteEvent', event);
						}
					});
					resolve();
				},
				(error) => {
					console.error('Firestore error!', error.message);
					reject('Firestore error! Check console log.');
				}
			);

			commit('setEventsUnsubscriber', events_unsubscriber);
		});
	},

	stopListeningEventsData: function ({ state, commit }) {
		if (state.events_unsubscriber) state.events_unsubscriber();
		commit('clear');
	},

	getEventData: async function ({ rootState, commit }, event_id) {
		const user_id = rootState.user.uid;
		const firestore = Vue.firebase.firestore;

		return await firestore()
			.doc(`users/${user_id}/events/${event_id}`)
			.get()
			.then((snapshot) => {
				if (!snapshot.exists) {
					return 'Doesnt exist';
				} else {
					const data = snapshot.data();
					commit('getEventData', data);
					return data;
				}
			})
			.catch((error) => {
				console.error('Unavailable: ', error.type);
			});
	},

	getEventDataProjection: function ({ commit }, data) {
		const firestore = Vue.firebase.firestore;
		let { user_id, event_id } = data;

		return new Promise((resolve) => {
			firestore()
				.doc(`users/${user_id}/events/${event_id}`)
				.get()
				.then((snapshot) => {
					if (!snapshot.exists) {
						resolve();
					} else {
						const data = snapshot.data();
						commit('getEventData', data);
						resolve();
					}
				})
				.catch((error) => error);
		});
	},

	/*
	 *   Helper function get Event Information, only to use locally inside store -> (Vuex)
	 */
	getEventInformation: async function ({ rootState }, data) {
		const firestore = Vue.firebase.firestore;
		const { user_id, event_id } = data;
		const userId = user_id || rootState.user.uid;

		return await firestore()
			.doc(`users/${userId}/events/${event_id}`)
			.get()
			.then((snapshot) => {
				if (!snapshot.exists) {
					return;
				} else {
					const data = snapshot.data();
					return data;
				}
			})
			.catch((error) => error);
	},

	finalizeEvent: async function ({ rootState, dispatch }, data) {
		const { user_id, access_id, event_id, twitch_access_id } = data;
		const userId = user_id || rootState.user.uid;

		const date = Date.now();
		const finish_date = date.toString();

		let { start_date } = await dispatch('getEventInformation', data);
		let duration = date - parseInt(start_date, 10);

		const firestore = Vue.firebase.firestore();
		const batch = firestore.batch();

		// set the access document to this event as unavailable
		const accessDoc = firestore.doc(`access/${access_id}`);
		batch.update(accessDoc, { status: 'unavailable' });

		if (twitch_access_id) {
			const twitchAccess = firestore.doc(`access/${twitch_access_id}`);
			batch.update(twitchAccess, { status: 'unavailable' });
		}

		// add action of event finished
		const actionDoc = firestore.collection(`users/${userId}/events/${event_id}/actions`).doc();
		batch.set(
			actionDoc,
			buildEventAction({
				action_type: 'EVENT_FINISHED',
				author_type: 'host',
				author_id: rootState.user.uid,
			})
		);

		// set this event as finalized
		const eventDoc = firestore.doc(`users/${userId}/events/${event_id}`);
		batch.set(
			eventDoc,
			{
				status: 'finalized',
				finish_date,
				duration,
			},
			{ merge: true }
		);

		await batch.commit();
		await dispatch('speakers/finalizeEventSpeakers', event_id, { root: true });

		return true;
	},

	addEventAction({ commit }, data) {
		const { event_id, action_data, app } = data;

		const firebaseApp = app || Vue.firebase;
		const functions = firebaseApp.functions();

		return new Promise((resolve, reject) => {
			const createEventAction = functions.httpsCallable('createEventAction');

			createEventAction({ event_id, actionData: action_data })
				.then((res) => {
					commit('addEventAction', { id: res.action_id, ...action_data });
					resolve(res.action_id);
				})
				.catch((error) => {
					console.error('Error adding event action.', error);
					reject(error);
				});
		});
	},

	/* live event */
	startListeningLiveEvent: async function ({ commit }, data) {
		commit('clearLiveEvent');

		const { user_id, event_id, app } = data;

		const firebaseApp = app || Vue.firebase;
		const db = firebaseApp.firestore();

		// get the event document
		const eventDoc = db.doc(`users/${user_id}/events/${event_id}`);

		return new Promise((resolve, reject) => {
			// listen to changes in the event document
			const event_live_unsub = eventDoc.onSnapshot(
				(docSnapshot) => {
					if (docSnapshot.exists) {
						const event = {
							id: docSnapshot.id,
							user_id: user_id,
							...docSnapshot.data(),
						};
						commit('updateLiveEvent', event);
						resolve(event);
					} else {
						commit('removeLiveEvent');
						resolve();
					}
				},
				(error) => {
					console.error('Firestore error!', error.message);
					reject('Firestore error! Check console log.');
				}
			);

			commit('setEventLiveUnsubscriber', event_live_unsub);
		});
	},

	stopListeningLiveEvent: function ({ state, commit }) {
		// unsubscribe to cancel the live event listening
		if (state.event_live_unsub) state.event_live_unsub();
		commit('clearLiveEvent');
	},

	updateLiveEvent({ rootState }, data) {
		const user_id = rootState.user.uid;
		const event_id = data.event_id;

		return new Promise((resolve, reject) => {
			const firestore = Vue.firebase.firestore();
			const eventRef = firestore.doc(`users/${user_id}/events/${event_id}`);

			firestore
				.runTransaction((transaction) => {
					return transaction.get(eventRef).then((eventDoc) => {
						if (!eventDoc.exists) {
							throw 'Event does not exists!';
						}
						return transaction.set(eventRef, data.eventData, { merge: true });
					});
				})
				.then(resolve)
				.catch(reject);
		});
	},

	async getLatestEvents(_, userId) {
		/**
		 * @type {firebase.default.firestore.Firestore}
		 */
		const db = Vue.firebase.firestore();
		const snapshot = await db.collection(`users/${userId}/events`).orderBy('start_date', 'desc').limit(10).get();
		return snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
	},

	async createAudioTestEvent({ rootState }) {
		/**
		 * @type {firebase.default.firestore.Firestore}
		 */
		const db = Vue.firebase.firestore();

		const user_id = rootState.user.uid;

		const snapshot = await db.doc(`users/${user_id}/events/audio_test`).get();
		if (snapshot.exists) {
			return {
				id: snapshot.id,
				...snapshot.data(),
			};
		}

		// return await dispatch('createNewEvent', {
		const audio_test_event_data = {
			name: 'audio_test',
			name_captions: false,
			host_controls_lang: true,
			projection_url: true,
		};
		await snapshot.ref.set(audio_test_event_data);

		return {
			id: snapshot.id,
			...audio_test_event_data,
		};
	},

	setRecentLanguages({ commit }, { userId, eventId }) {
		const storedList = localStorage.getItem(`languages-${userId}-${eventId}`);
		commit('setRecentLanguages', JSON.parse(storedList) || []);
	},

	addNewRecentLanguage({ commit, state }, { source, target, eventId, userId }) {
		const newItem = {
			source,
			target: target[0],
			lastUsed: Date.now(),
		};

		const newState = [...state.recentLanguages];

		// Check if the same item exists, update its timestamp
		const existingItemIndex = newState.findIndex((item) => item.source === source && item.target === target[0]);
		if (existingItemIndex !== -1) {
			newState[existingItemIndex].lastUsed = newItem.lastUsed;
		} else {
			// Add the new item to the newState
			if (newState.length >= 4) {
				// Find the oldest item based on lastUsed and remove it
				const oldestItemIndex = newState.reduce((oldestIndex, currentItem, currentIndex, array) => {
					return currentItem.lastUsed < array[oldestIndex].lastUsed ? currentIndex : oldestIndex;
				}, 0);
				newState.splice(oldestItemIndex, 1);
			}
			newState.push(newItem);
		}

		commit('setRecentLanguages', newState);
		localStorage.setItem(`languages-${userId}-${eventId}`, JSON.stringify(newState));
	},
};

function buildEventAction(action_data) {
	const new_action = {
		...action_data,
	};
	new_action.date = Vue.firebase.firestore.Timestamp.now();
	return new_action;
}

export default {
	state,
	getters,
	mutations,
	actions,
	namespaced: true,
};
