import Vue from 'vue';

const state = {
	// Firebase Auth data
	emailVerified: '',
	uid: '',
	email: '',
	name: '',
	role: '',
	credit: 0,
	prepaid_plan: null,
	confidentialSubtitles: false,
	features: [],

	user_unsubscriber: null,

	active_users: [],
	users: [],
	user: null,
};

const getters = {
	getAccessToken: (state) => state.access_token,
	getUserId: (state) => state.uid,
	getUserCredit: (state) => state.credit,
	getCurrentUser: (state) => ({ id: state.uid, name: state.name, email: state.email, role: state.role }),
	currentUserName: (state) => state.name,
	currentUserFirstName: (state) => state.name.split(' ')[0],
	currentUserEmail: (state) => state.email,
	getActiveUsers: (state) => state.active_users,
	isAdmin: (state) => state.role === 'admin',
	isPrepaidPlan: (state) => state.prepaid_plan,
	getFeatures: (state) => state.features,
	getUsers: (state) => state.users,
	getUser: (state) => state.user,
	confidentialSubtitlesEnabled: (state) => state.confidentialSubtitles,
};

const mutations = {
	setAccessToken: (state, token) => (state.access_token = token),

	setCurrentUser: (state, user) => {
		if (!user) return;
		state.emailVerified = user.emailVerified;
		state.uid = user.uid;
		state.email = user.email;
	},

	setCurrentUserData: (state, user) => {
		state.name = user.name;
		state.role = user.role;
		state.features = user.features || [];
		state.confidentialSubtitles = user.confidentialSubtitles;
		state.credit = user.credit || 0;
		state.prepaid_plan = user.prepaid_plan || false;
	},

	setCurrentUserUnsubscriber: (state, unsub) => (state.user_unsubscriber = unsub),
	setOnAuthChangedUnsubscriber: (state, unsub) => (state.auth_unsubscriber = unsub),

	clear: (state) => {
		state.uid = '';
		state.emailVerified = '';
		state.email = '';
		state.name = '';
		state.role = '';
		state.features = [];
	},

	setActiveUsers: (state, users) => (state.active_users = users),

	addUser: (state, user) => {
		state.users.push(user);
	},

	updateUser: (state, user) => {
		const index = state.users.findIndex((usr) => usr.id === user.id);
		if (index > 0) state.users.splice(index, 1, user);
	},

	deleteUser: (state, user) => {
		const index = state.users.findIndex((usr) => usr.id === user.id);
		if (index > 0) state.users.splice(index, 1);
	},

	clearUsers: (state) => {
		state.users = [];
	},

	setUser: (state, user) => {
		state.user = user;
	},
	clearUser: (state) => {
		state.user = null;
	},
};

const actions = {
	listenUserData: function ({ commit }) {
		return new Promise((resolve, reject) => {
			const unsub = Vue.firebase.auth().onAuthStateChanged(function (user) {
				if (user) {
					commit('setCurrentUser', user);
					resolve();
				} else {
					reject('User null');
				}
			});
			commit('setOnAuthChangedUnsubscriber', unsub);
		});
	},

	stopListenAuthStateChanged({ state }) {
		if (state.auth_unsubscriber) state.auth_unsubscriber();
	},

	login: function (context, login_data) {
		const auth = Vue.firebase.auth;
		return new Promise((resolve, reject) => {
			const persistence = login_data.persistence ? 'LOCAL' : 'SESSION';

			auth()
				.setPersistence(auth.Auth.Persistence[persistence])
				.then(function () {
					return auth().signInWithEmailAndPassword(login_data.email, login_data.password);
				})
				.then(resolve)
				.catch((error) => {
					reject(error.message);
				});
		});
	},

	logout: function ({ dispatch }) {
		const auth = Vue.firebase.auth();

		dispatch('stopListeningCurrentUser');
		return auth.signOut();
	},

	startListeningCurrentUser: function ({ commit, state }) {
		const db = Vue.firebase.firestore();
		const user_id = state.uid;

		return new Promise((resolve) => {
			let first_snap = true; // controls resolve or not

			const user_unsubscriber = db.doc(`users/${user_id}`).onSnapshot((snapshot) => {
				const user = snapshot.data();
				user.id = snapshot.id;
				commit('setCurrentUserData', user);

				if (first_snap) {
					resolve();
					first_snap = false;
				}
			});

			commit('setCurrentUserUnsubscriber', user_unsubscriber);
		});
	},

	stopListeningCurrentUser: function ({ state, commit }) {
		if (state.user_unsubscriber) state.user_unsubscriber();
		commit('clear');
	},

	sendPasswordResetEmail(_, email) {
		const auth = Vue.firebase.auth();

		return new Promise((resolve, reject) => {
			auth
				.sendPasswordResetEmail(email)
				.then(resolve)
				.catch((error) => {
					reject(error.code);
				});
		});
	},

	userExist: async function (_, { user_id }) {
		const db = Vue.firebase.firestore();

		const userDoc = db.doc(`users/${user_id}`);

		const user = await userDoc.get();

		if (!user.exists || user.data().status == 'inactive') return false;

		return true;
	},

	fetchActiveUsers: ({ commit }) => {
		return new Promise((resolve, reject) => {
			const db = Vue.firebase.firestore();
			const usersCollectionRef = db.collection(`users`).where('active', '==', true);
			usersCollectionRef
				.get()
				.then((querySnapshot) => {
					const users = [];
					querySnapshot.forEach((doc) => {
						users.push({ id: doc.id, ...doc.data() });
					});
					users.sort((a, b) => {
						if (a.name > b.name) {
							return 1;
						}
						if (a.name < b.name) {
							return -1;
						}
						// a must be equal to b
						return 0;
					});
					commit('setActiveUsers', users);
					resolve(users);
				})
				.catch(reject);
		});
	},

	startListeningUsers: ({ commit }) => {
		commit('clearUsers');

		/**
		 * @type {firebase.default.firestore.Firestore}
		 */
		const db = Vue.firebase.firestore();
		let query = db.collection('users');

		const unsubscriber = query.onSnapshot((querySnapshot) => {
			querySnapshot.docChanges().forEach((change) => {
				const user = { ...change.doc.data(), id: change.doc.id };
				switch (change.type) {
					case 'added':
						commit('addUser', user);
						break;
					case 'modified':
						commit('updateUser', user);
						break;
					case 'removed':
						commit('deleteUser', user);
						break;
					default:
						break;
				}
			});
		});

		return unsubscriber;
	},

	startListeningUser: ({ commit }, user_id) => {
		commit('clearUser');

		/**
		 * @type {firebase.default.firestore.Firestore}
		 */
		const db = Vue.firebase.firestore();
		const unsubscriber = db.doc(`users/${user_id}`).onSnapshot((docSnapshot) => {
			const user = { id: docSnapshot.id, ...docSnapshot.data() };
			commit('setUser', user);
		});

		return unsubscriber;
	},

	updateUser: async (_, { userId, user }) => {
		/**
		 * @type {firebase.default.functions.Functions}sendUserInvite
		 */
		console.log('teste');
		const functions = Vue.firebase.functions();
		const updateUser = functions.httpsCallable('users-updateUser');

		return await updateUser({ userId, user });
	},

	updateUserSettings: async (_, { userId, settings }) => {
		/**
		 * @type {firebase.default.functions.Functions}
		 */
		const functions = Vue.firebase.functions();
		const updateUserSettings = functions.httpsCallable('users-updateUserSettings');

		return await updateUserSettings({ userId, settings });
	},

	async sendUserInvite(_, form) {
		/**
		 * @type {firebase.functions.Functions}
		 */
		const functions = Vue.firebase.functions();
		const sendUserInvite = functions.httpsCallable('users-sendUserInvite');
		return await sendUserInvite({
			name: form.name,
			email: form.email,
		});
	},
};

export default {
	state,
	mutations,
	getters,
	actions,
	namespaced: true,
};
