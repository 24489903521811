<template>
	<form class="form-inline">
		<div class="form-group">
			<label for="pageLocaleSelect">
				<span class="text-light h4 my-auto mr-2">
					<i class="fa fa-globe-americas"></i>
				</span>
			</label>
			<select
				id="pageLocaleSelect"
				v-model="localeSelected"
				class="form-control rounded-purple rounded-purple-dark"
				@change="handleInput"
			>
				<option v-t="'selectLanguage'" value selected disabled></option>
				<option v-for="o in localeOptions" :key="o.value" :value="o.value">{{ o.text }}</option>
			</select>
		</div>
	</form>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	props: {
		value: {
			type: String,
			default: 'pt-BR',
		},
	},

	data() {
		return {
			localeSelected: this.value,
			localeOptions: [
				{ text: this.$t('spanish', 'es'), value: 'es' },
				{ text: this.$t('english', 'en'), value: 'en' },
				{ text: this.$t('portuguese', 'pt-BR'), value: 'pt-BR' },
			],
		};
	},

	computed: {
		...mapGetters(['locale']),
	},

	watch: {
		value(newValue) {
			this.localeSelected = newValue;
		},
		localeSelected(newValue) {
			localStorage.setItem('locale', newValue);
			this.$store.dispatch('setLocale', newValue);
		},
	},

	created() {
		this.localeSelected = this.locale;
	},

	methods: {
		handleInput() {
			this.$emit('input', this.localeSelected);
		},
	},
};
</script>

<style scoped>
select {
	width: 8rem !important;
}

i {
	font-size: 1.2rem;
}

form {
	background-color: #28264e;
	border-radius: 1rem;
	padding-inline: 0.75rem;
}

#pageLocaleSelect {
	padding: 0;
	border-radius: 0 1rem 1rem 0;
}
</style>
