<template>
	<div class="notifications-list">
		<transition-group name="list">
			<component
				:is="componentType(notification.type)"
				v-for="notification in notifications"
				:key="notification.id"
				:notification="notification"
			/>
		</transition-group>
		<b-dropdown-text v-if="notifications.length == 0" style="width: 420px; height: 100%">
			<span class="empty-notifications">
				<i class="fa-solid fa-bell"></i><br />
				{{ $t('notifications.liveHere') }}
			</span>
		</b-dropdown-text>
	</div>
</template>

<script>
import NotificationsItemPayment from './NotificationsItemPayment.vue';
import NotificationsItemGeneric from './NotificationsItemGeneric.vue';

export default {
	props: {
		notifications: {
			type: Array,
			required: true,
		},
	},
	methods: {
		componentType(type) {
			switch (type) {
				case 'payment':
					return NotificationsItemPayment;
				case 'generic':
				default:
					return NotificationsItemGeneric;
			}
		},
	},
};
</script>

<style scoped lang="scss">
.notifications-list {
	height: 480px;
	width: 100%;
	margin-bottom: 0.3rem;
	overflow-y: scroll;
	overflow-x: hidden;
	scrollbar-gutter: stable;
	position: relative;

	&:hover {
		--sb-thumb-color: var(--primary);
	}

	--sb-track-color: transparent;
	--sb-thumb-color: transparent;

	--sb-size: 4px;

	scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);

	&::-webkit-scrollbar {
		width: var(--sb-size);
	}

	&::-webkit-scrollbar-track {
		background: var(--sb-track-color);
		border-radius: 4px;
	}

	&::-webkit-scrollbar-thumb {
		background: var(--sb-thumb-color);
		border-radius: 0.6rem 0.6rem 0.6rem 0.6rem;
	}

	p {
		height: 100%;
	}
}

.empty-notifications {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	color: #303030;
	opacity: 0.3;
	font-weight: 400;
	font-size: 1.2rem;

	i {
		font-size: 4rem;
	}
}

.list-enter,
.list-leave-to {
	opacity: 0;
	transform: scale(80%);
}

.list-leave-active {
	transition: all 0.2s;
	position: absolute;
}
</style>
