<template>
	<div>
		<b-alert
			:show="dismissCountDown"
			fade
			variant="danger"
			@dismissed="dismissCountDown = 0"
			@dismiss-count-down="countDownChanged"
		>
			{{ message }}
		</b-alert>
		<form action="#" role="form" class="px-md-4 px-lg-5 px-xl-4" @submit.prevent="resetPassword">
			<i18n path="passwordResetForm.header" tag="div" class="form-text mb-3">
				<template #email>
					<strong>{{ email }}</strong>
				</template>
			</i18n>
			<new-password-form
				:validate="newPasswordFormValidate"
				:new-password.sync="newPassword"
				:confirm-password.sync="confirmPassword"
				:is-valid.sync="newPasswordValid"
			/>
			<div class="form-group mt-4">
				<button id="LoginBtn" type="submit" class="btn btn-pink btn-block btn-lg rounded rounded-pill">
					{{ $t('passwordResetForm.button') }}
				</button>
			</div>
		</form>
	</div>
</template>

<script>
import Vue from 'vue';
import NewPasswordForm from '../NewPasswordForm.vue';

export default {
	name: 'PasswordResetForm',
	components: { NewPasswordForm },

	props: {
		actionCode: { type: String, default: '' },
		email: { type: String, default: '' },
	},

	data() {
		return {
			newPassword: '',
			confirmPassword: '',
			newPasswordValid: false,
			newPasswordFormValidate: false,

			dismissSecs: 3,
			dismissCountDown: 0,
			message: '',
		};
	},

	methods: {
		resetPassword() {
			this.newPasswordFormValidate = true;

			// validations
			if (!this.newPasswordValid) return;

			const loader = this.$loading.show();

			// Save the new password.
			const auth = Vue.firebase.auth();

			auth
				.confirmPasswordReset(this.actionCode, this.newPassword)
				.then(() => {
					// Password reset has been confirmed and new password updated.
					// TODO: Display a link back to the app, or sign-in the user directly
					// if the page belongs to the same domain as the app:
					// auth.signInWithEmailAndPassword(accountEmail, newPassword);
					// TODO: If a continue URL is available, display a button which on
					// click redirects the user back to the app via continueUrl with
					// additional state determined from that URL's parameters.
					// data valid
					this.$root.$bvToast.toast(this.$t('toastMessages.passwordChanged'), {
						variant: 'success',
						solid: true,
						noCloseButton: true,
						toaster: 'b-toaster-top-center',
					});

					this.$emit('success');
				})
				.catch((error) => {
					// Error occurred during confirmation. The code might have expired or the
					// password is too weak.
					console.error(error);
					this.showError(error.message);
				})
				.finally(() => {
					loader.hide();
				});
		},

		countDownChanged(dismissCountDown) {
			this.dismissCountDown = dismissCountDown;
		},

		showError(message) {
			this.dismissCountDown = this.dismissSecs;
			this.message = message;
		},
	},
};
</script>
