import Vue from 'vue';

const state = () => ({
	registrationEnabled: false,
});

const getters = {
	isTestEnabled: (state) => state.testEnabled,
	isRegistrationEnabled: (state) => state.registrationEnabled,
};

export const mutations = {
	setInitialState: (state, { registration }) => {
		state.registrationEnabled = registration;
	},
};

const actions = {
	async setFeatureFlagState({ commit }) {
		const db = Vue.firebase.firestore();
		const query = db.doc('settings/featureFlags');
		const result = await query.get();
		commit('setInitialState', result.data());
	},
};

export default {
	state,
	getters,
	mutations,
	actions,
	namespaced: true,
};
