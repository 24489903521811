export default {
	apiKey: 'AIzaSyD0julqrzHER96zyvp4tYxt4FRjG2wz9rU',
	authDomain: 'skylar-cc.firebaseapp.com',
	databaseURL: 'https://skylar-cc.firebaseio.com',
	projectId: 'skylar-cc',
	storageBucket: 'skylar-cc.appspot.com',
	messagingSenderId: '454513468319',
	appId: '1:454513468319:web:6c3d12e362e2a6d516d3aa',
	measurementId: 'G-ML024H5RTK',
};
