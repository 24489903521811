export default function (credits) {
	const p1 = 8;
	const p2 = 7;
	const p3 = 6;
	const p4 = 5;

	const tier1Limit = 120;
	const tier2Limit = 300;
	const tier3Limit = 1000;

	let totalCharge = 0;

	if (credits <= tier1Limit) {
		totalCharge = credits * p1;
	} else if (credits <= tier2Limit) {
		totalCharge = tier1Limit * p1 + (credits - tier1Limit) * p2;
	} else if (credits <= tier3Limit) {
		totalCharge = tier1Limit * p1 + (tier2Limit - tier1Limit) * p2 + (credits - tier2Limit) * p3;
	} else {
		totalCharge =
			tier1Limit * p1 + (tier2Limit - tier1Limit) * p2 + (tier3Limit - tier2Limit) * p3 + (credits - tier3Limit) * p4;
	}

	return totalCharge;
}
