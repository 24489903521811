const { detect } = require('detect-browser');

/**
 * Try to get browser's preferred language.
 * See: {@link https://phrase.com/blog/posts/ultimate-guide-to-vue-localization-with-vue-i18n/#Detecting_the_Users_Preferred_Locale_in_the_Browser|Detecting the User's Preferred Locale in the Browser}
 * @param {*} options Options: countryCodeOnly (indicates if wants just language country code, e.g.: pt-BR --> pt)
 * @returns Browser's preferred language or undefined.
 */
export const getBrowserLocale = function (options = {}) {
	const defaultOptions = { countryCodeOnly: false };
	const opt = { ...defaultOptions, ...options };
	const navigatorLocale = navigator.languages !== undefined ? navigator.languages[0] : navigator.language;
	if (!navigatorLocale) {
		return undefined;
	}
	const trimmedLocale = opt.countryCodeOnly ? navigatorLocale.trim().split(/-|_/)[0] : navigatorLocale.trim();
	return trimmedLocale;
};

export const getBrowser = function () {
	const browser = detect();
	return browser;
};

export function addAlpha(color, opacity) {
	// coerce values so ti is between 0 and 1.
	const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
	return color + _opacity.toString(16).toUpperCase();
}

/**
 * a function to detect when the color of the text should be white or black, based on the color informed by parameter
 * @param {String} bgColor hex color
 */
export function getTextColorBasedOnBgColor(bgColor) {
	const color = bgColor.substring(1); // strip #
	const rgb = parseInt(color, 16); // convert rrggbb to decimal
	const r = (rgb >> 16) & 0xff; // extract red
	const g = (rgb >> 8) & 0xff; // extract green
	const b = (rgb >> 0) & 0xff; // extract blue

	const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

	if (luma < 100) {
		return '#f8f9fa';
	} else {
		return '#392974';
	}
}
