export const AllMonths = [
	{ value: '', text: 'Mês', disabled: true },
	{ value: '01', text: '01' },
	{ value: '02', text: '02' },
	{ value: '03', text: '03' },
	{ value: '04', text: '04' },
	{ value: '05', text: '05' },
	{ value: '06', text: '06' },
	{ value: '07', text: '07' },
	{ value: '08', text: '08' },
	{ value: '09', text: '09' },
	{ value: '10', text: '10' },
	{ value: '11', text: '11' },
	{ value: '12', text: '12' },
];
