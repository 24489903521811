<template>
	<nav class="navbar navbar-expand navbar-dark bg-glass shadow-sm">
		<a class="navbar-brand" href="#">
			<img src="@/assets/images/legenda-live-logo-white.png" class="d-inline-block" alt="" width="160" />
			<!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
			<!-- <span class="text-uppercase ml-3">Legenda Live</span> -->
		</a>
		<button
			class="navbar-toggler"
			type="button"
			data-toggle="collapse"
			data-target="#navbarSupportedContent"
			aria-controls="navbarSupportedContent"
			aria-expanded="false"
			aria-label="Toggle navigation"
		>
			<span class="navbar-toggler-icon"></span>
		</button>
		<div id="navbarSupportedContent" class="collapse navbar-collapse">
			<ul class="navbar-nav mr-5 navbar-right-top">
				<router-link v-slot="{ href, navigate, isActive, isExactActive }" to="/dashboard" custom>
					<li class="nav-item" :class="[isActive && 'active', isExactActive && 'router-link-exact-active']">
						<a :href="href" class="nav-link" @click="navigate">{{ $t('navBarOptions.events') }}</a>
					</li>
				</router-link>
				<router-link v-slot="{ href, navigate, isActive, isExactActive }" to="/report" custom>
					<li class="nav-item" :class="[isActive && 'active', isExactActive && 'router-link-exact-active']">
						<a :href="href" class="nav-link" @click="navigate">{{ $t('navBarOptions.report') }}</a>
					</li>
				</router-link>
				<router-link v-if="isAdmin" v-slot="{ href, navigate, isActive, isExactActive }" to="/admin" custom>
					<li class="nav-item" :class="[isActive && 'active', isExactActive && 'router-link-exact-active']">
						<a :href="href" class="btn btn-outline-warning text-uppercase mx-2" @click="navigate">
							{{ $t('admin') }}
						</a>
					</li>
				</router-link>
			</ul>
			<ul class="navbar-nav ml-auto navbar-right-top">
				<li>
					<credits-display @click="showAddCreditsModal" />
				</li>
				<li>
					<language-switcher />
				</li>
				<li>
					<notifications-button />
				</li>
				<li class="nav-item dropdown nav-user">
					<a
						id="navbarDropdownMenuLink2"
						class="nav-link dropdown-toggle"
						href="#"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
					>
						<div
							v-if="currentUser.name"
							:class="[
								'd-inline-flex justify-content-center align-items-center user-avatar-md rounded-circle avatar-initials',
								isAdmin ? 'border border-2 border-warning' : '',
							]"
						>
							{{ nameInitials }}
						</div>
					</a>
					<div class="dropdown-menu dropdown-menu-right nav-user-dropdown" aria-labelledby="navbarDropdownMenuLink2">
						<div class="d-flex align-items-center justify-content-between">
							<div class="nav-user-info d-inline-block">
								<h5 class="m-0">{{ $t('helloUser', [firstName]) }}</h5>
							</div>
							<div class="px-3">
								<span v-if="isAdmin" class="badge badge-pill badge-warning text-uppercase">
									{{ $t('admin') }}
								</span>
							</div>
						</div>
						<!--
							<a class="dropdown-item" href="#"><i class="fas fa-user mr-2"></i>Account</a>
						-->
						<router-link v-slot="{ href, navigate }" to="/profile">
							<a class="dropdown-item" :href="href" @click="navigate"
								><i class="fas fa-cog mr-2"></i>{{ $t('settings') }}</a
							>
						</router-link>
						<router-link v-if="isPrepaidPlan" v-slot="{ href, navigate }" to="/payments">
							<a class="dropdown-item" :href="href" @click="navigate"
								><i class="fas fa-credit-card mr-2"></i>{{ $t('payments.payments') }}</a
							>
						</router-link>
						<a class="dropdown-item" href="#" @click.prevent="logOut"
							><i class="fas fa-power-off mr-2"></i>{{ $t('logout') }}</a
						>
					</div>
				</li>
			</ul>
		</div>
		<credits-modal />
	</nav>
</template>

<script>
import { mapGetters } from 'vuex';
import LanguageSwitcher from './LanguageSwitcher.vue';
import NotificationsButton from './Notifications/NotificationsButton.vue';
import CreditsModal from './Credits/CreditsModal.vue';
import CreditsDisplay from './Credits/CreditsDisplay.vue';

export default {
	name: 'TheNavBar',
	components: {
		LanguageSwitcher,
		NotificationsButton,
		CreditsModal,
		CreditsDisplay,
	},
	computed: {
		...mapGetters({
			/** @type {{name: String, email: String}} */
			currentUser: 'user/getCurrentUser',
			isAdmin: 'user/isAdmin',
			isPrepaidPlan: 'user/isPrepaidPlan',
		}),
		firstName() {
			return this.currentUser.name.split(' ')[0];
		},
		nameInitials() {
			const names = this.currentUser.name.split(' ');
			if (names.length) {
				let initials = '';
				if (names.length >= 1) {
					initials += names[0][0];
				}
				if (names.length >= 2) {
					initials += names[names.length - 1][0];
				}
				return initials;
			}

			return 'U';
		},
	},
	created() {
		const loader = this.$loading.show();

		Promise.all([this.$store.dispatch('user/startListeningCurrentUser')])
			.catch(this.handleListenerError)
			.finally(loader.hide);
	},
	methods: {
		logOut() {
			this.$store
				.dispatch('user/logout')
				.then(() => this.$router.push('/login'))
				.catch(() =>
					this.$root.$bvToast.toast(this.$t('toastMessages.logoutError'), {
						variant: 'danger',
						solid: true,
						noCloseButton: true,
						toaster: 'b-toaster-top-center',
					})
				);
		},
		showAddCreditsModal() {
			this.$store.commit('setAddCreditsModalVisibility', true);
		},
		showFormModal() {
			this.$store.commit('setFormModalVisibility', true);
		},
	},
};
</script>

<style lang="scss" scoped>
@use '~@/assets/styles/sass/config/config' as *;

/* based on https://bbbootstrap.com/snippets/navigation-bar-search-and-user-icons-83384530 */

.navbar {
	border-top: 0px;
	border-left: 0px;
	border-right: 0px;
	z-index: 1001;
}

.nav-item {
	.nav-link {
		margin-left: 0.5rem;
		margin-right: 0.5rem;
		padding-left: 0.75rem;
		padding-right: 0.75rem;
		border-radius: 0.2rem;
		color: $dark-lighter;
		text-align: center;

		&:hover {
			background-color: #fff1;
		}

		&:active {
			background-color: #fff2;
		}
	}

	&.active {
		.nav-link {
			background-color: #fff2;

			&:hover {
				background-color: #fff3;
			}
		}
	}
}

.add-credits {
	margin-left: 0.5rem;
	margin-right: 0.5rem;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
	border-radius: 0.2rem;
	color: white;
	text-align: center;
}

// .navbar-right-top {
// 	.nav-item {
// 		border-right: 1px solid #ffffff3a;
// 	}
// 	.nav-item:last-child {
// 		border: none;
// 	}
// }

.nav-user {
	// .nav-link {
	// 	color: red;
	// }

	.dropdown-toggle::after {
		margin-left: 0.75rem;
	}
}

.nav-user-info {
	line-height: 1.4;
	padding: 12px;
	font-size: 13px;
	border-radius: 2px 2px 0 0;
	color: $dark;

	.status {
		float: left;
		top: 7px;
		left: 0px;
	}
}

.nav-user-dropdown {
	padding: 0px;
	min-width: 230px;
	margin: 0px;
	z-index: 1002;

	.dropdown-item {
		display: block;
		width: 100%;
		padding: 12px 22px 15px;
		clear: both;
		font-weight: 400;
		color: $dark;
		text-align: inherit;
		white-space: nowrap;
		background-color: transparent;
		border: 0;
		line-height: 0.4;
	}

	.dropdown-item:hover {
		background-color: lighten($color: $dark-lighter, $amount: 10%);
	}
}

.user-avatar-md {
	height: 32px;
	width: 32px;
}

.avatar-initials {
	background-color: $dark-lighter;
	color: $dark;
}

.border-2 {
	border-width: 2px !important;
}

.navbar-nav {
	align-items: center;
}
</style>
