<template>
	<div id="app">
		<!-- Header -->
		<div v-if="navbarVisible">
			<the-nav-bar />
		</div>
		<!-- Content -->
		<div :class="[navbarVisible ? 'mt-5' : '']">
			<router-view />
		</div>
		<!-- Footer -->
		<!-- <div v-if="backgroundFooter" id="background-footer">
			<img src="@/assets/svg/wave-footer.svg" alt="Footer" />
		</div> -->
	</div>
</template>

<script>
import { getBrowserLocale } from './util';
import { mapGetters } from 'vuex';
import TheNavBar from './components/TheNavBar.vue';

export default {
	name: 'App',
	components: { TheNavBar },
	computed: {
		...mapGetters({
			locale: 'locale',
			isNavBarVisible: 'isNavBarVisible',
			getUserId: 'user/getUserId',
			backgroundFooter: 'backgroundFooter',
		}),
		navbarVisible() {
			return this.isNavBarVisible && !!this.getUserId;
		},
	},
	watch: {
		locale(newValue) {
			this.$i18n.locale = newValue;
		},
	},
	created() {
		// set system locale in store
		const browserLocale = getBrowserLocale();
		const selectedLocale = localStorage.getItem('locale');
		this.$store.dispatch('setLocale', selectedLocale || browserLocale);

		this.$store.commit('setNavBarVisibility', true);
		this.$store.dispatch('startListeningConnection');
		this.$store.dispatch('featureFlags/setFeatureFlagState');
		this.$store.dispatch('webSockets/setWsEndpoints');
	},
};
</script>

<style lang="scss" scoped>
@use '~bootstrap/scss/bootstrap.scss' as bs;

/*
* FOOTER
*/
#background-footer {
	height: auto;
	z-index: -10;
	overflow: hidden;
	opacity: 0.3;

	@include bs.media-breakpoint-up(lg) {
		height: 300px;
		position: fixed;
		right: 0;
		bottom: 0;
		left: 0;
	}
}
</style>
