<template>
	<div>
		<div class="form-group">
			<label for="newPwd">{{ $t('newPassword') }}</label>
			<input
				id="newPwd"
				v-model.trim="$v.form.newPassword.$model"
				type="password"
				name="newPwd"
				class="form-control"
				:class="$v.form.newPassword.$error ? 'is-invalid' : ''"
				:placeholder="$t('newPasswordPlaceholder')"
				autocomplete="new-password"
				:disabled="disabled"
			/>
			<small v-show="$v.form.newPassword.$error" class="text-danger font-weight-bold">
				{{
					!$v.form.newPassword.required
						? $t('newPasswordIsMandatory')
						: !$v.form.newPassword.minChars
						? $tc('newPasswordMinLength', 6)
						: ''
				}}
			</small>
		</div>
		<div class="form-group">
			<label for="confirmPwd">{{ $t('confirmPassword') }}</label>
			<input
				id="confirmPwd"
				v-model.trim="$v.form.confirmPassword.$model"
				type="password"
				name="confirmPwd"
				class="form-control"
				:class="$v.form.confirmPassword.$error ? 'is-invalid' : ''"
				:placeholder="$t('confirmPasswordPlaceholder')"
				autocomplete="new-password"
				:disabled="disabled"
			/>
			<small v-show="$v.form.confirmPassword.$error" class="text-danger font-weight-bold">
				{{
					!$v.form.confirmPassword.required
						? $t('confirmPasswordIsMandatory')
						: !$v.form.confirmPassword.sameAsNewPassword
						? $t('confirmPasswordMismatch')
						: ''
				}}
			</small>
		</div>
	</div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, sameAs, minLength } from 'vuelidate/lib/validators';

export default {
	mixins: [validationMixin],
	props: {
		newPassword: { type: String, default: '' },
		confirmPassword: { type: String, default: '' },
		validate: Boolean,
		isValid: Boolean,
		disabled: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			form: {
				newPassword: '',
				confirmPassword: '',
			},
		};
	},
	validations: {
		form: {
			newPassword: {
				required,
				minChars: minLength(6),
			},
			confirmPassword: {
				required,
				sameAsNewPassword: sameAs('newPassword'),
			},
		},
	},
	watch: {
		validate(actual) {
			if (actual) {
				this.$v.$touch();
			}
		},
		'$v.form.$invalid': {
			handler(actual) {
				this.$emit('update:isValid', !actual);
				if (!actual) {
					this.$emit('update:newPassword', this.form.newPassword);
					this.$emit('update:confirmPassword', this.form.confirmPassword);
				}
			},
		},
	},
	beforeMount() {
		this.form.newPassword = this.newPassword;
		this.form.confirmPassword = this.confirmPassword;
	},
};
</script>
