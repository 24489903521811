import 'bootstrap';
import 'jquery';

// import all styles
import '@fortawesome/fontawesome-free/scss/brands.scss';
import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
import '@fortawesome/fontawesome-free/scss/regular.scss';
import '@fortawesome/fontawesome-free/scss/solid.scss';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue-loading-overlay/dist/vue-loading.css';
import './assets/styles/sass/custom.scss';

import { BootstrapVue } from 'bootstrap-vue';
import { ColorPanel, ColorPicker } from 'one-colorpicker';
import Vue from 'vue';
import CountryFlag from 'vue-country-flag';
import Loading from 'vue-loading-overlay';
import VueSweetalert2 from 'vue-sweetalert2';

import App from './App.vue';
import CardGlass from './components/CardGlass.vue';
import i18n from './i18n';
import FirebaseInit from './plugins/firebase';
import router from './router';
import { store } from './store';

// bootstrap vue
Vue.use(BootstrapVue);

// vue shortkey
Vue.use(require('vue-shortkey'));

// vue loading overlay
Vue.use(Loading, {
	canCancel: false,
	color: '#700abe',
	loader: 'spinner',
	width: 64,
	zIndex: 99999,
});

// sweet alert
Vue.use(VueSweetalert2);

// one-color (color picker component)
Vue.use(ColorPicker);
Vue.use(ColorPanel);

// add v-focus directive
Vue.directive('focus', {
	inserted: function (el) {
		el.focus();
	},
});

// custom firebase plugin
Vue.use(FirebaseInit);

// add global components
Vue.component('CardGlass', CardGlass);
Vue.component('CountryFlag', CountryFlag);

// turns off development mode tips and warnings, helping optimize the application for production
// by reducing bundle size and improving performance.
Vue.config.productionTip = process.env.NODE_ENV !== 'production';

new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App),
}).$mount('#app');
