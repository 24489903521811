<template>
	<div>
		<b-alert
			fade
			variant="danger"
			:show="dismissCountDown"
			@dismissed="dismissCountDown = 0"
			@dismiss-count-down="countDownChanged"
		>
			{{ message }}
		</b-alert>
		<b-alert
			fade
			variant="success"
			:show="succeed"
			@dismissed="dismissCountDown = 0"
			@dismiss-count-down="countDownChanged"
		>
			{{ $t('emailPasswordRedefinitionWasSent') }}
		</b-alert>

		<form action="#" role="form" class="px-md-4 px-lg-5 px-xl-4" @submit.prevent="tryLogin">
			<div class="form-text">{{ $t('insertEmailAddressToSendLink') }}</div>
			<div class="form-group py-3">
				<label for="email" aria-label="Email">{{ $t('email') }}</label>
				<input
					id="email"
					ref="emailInput"
					v-model="form.email"
					v-focus
					type="email"
					class="form-control"
					:class="$v.form.email.$error ? 'is-invalid' : ''"
					autocomplete="email"
				/>
				<small v-show="$v.form.email.$error" class="text-danger font-weight-bold">
					{{
						!$v.form.email.required
							? $t('emailFieldMandatory')
							: !$v.form.email.email
							? $t('emailFieldMustBeValid')
							: ''
					}}
				</small>
			</div>
			<div class="form-group">
				<button
					id="ResetBtn"
					type="submit"
					class="btn btn-pink btn-block btn-lg rounded rounded-pill"
					@click.prevent="forgotPasswd"
				>
					{{ $t('recoverPassword') }}
				</button>
			</div>
		</form>
	</div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('user');

import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';

export default {
	name: 'ForgotPasswordForm',
	mixins: [validationMixin],

	data() {
		return {
			form: {
				email: '',
			},
			dismissSecs: 3,
			dismissCountDown: 0,
			message: '',
			succeed: false,
		};
	},

	validations: {
		form: {
			email: {
				required,
				email,
			},
		},
	},

	methods: {
		countDownChanged(dismissCountDown) {
			this.dismissCountDown = dismissCountDown;
		},

		showError(errorCode) {
			this.dismissCountDown = this.dismissSecs;
			switch (errorCode) {
				case 'auth/user-not-found':
					this.message = this.$t('emailNotFound');
					break;
				case 'auth/invalid-email':
					this.message = this.$t('emailFieldMustBeValid');
					break;
				default:
					this.message = errorCode;
					break;
			}
		},

		forgotPasswd() {
			this.$v.$touch();
			if (this.$v.$invalid) return;

			const loader = this.$loading.show();
			this.sendPasswordResetEmail(this.form.email)
				.then(this.sendEmailPasswdSucceded)
				.catch(this.showError)
				.finally(loader.hide);
		},

		sendEmailPasswdSucceded() {
			this.succeed = true;
		},

		...mapActions(['sendPasswordResetEmail']),
	},
};
</script>
