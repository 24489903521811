export default function auth({ next, store, router }) {
	if (store.state.user.uid) return next();

	store
		.dispatch('user/listenUserData')
		.then(next)
		.catch(() => {
			router.push('/login');
		});
}
