<template>
	<notifications-item-base
		:title="notification.title"
		:read="notification.read"
		:body="notification.body"
		:time="notification.time"
		:notification-id="notification.id"
		icon="fa-solid fa-comment"
		color="blue"
	/>
</template>

<script>
import NotificationsItemBase from './NotificationsItemBase.vue';

export default {
	components: {
		NotificationsItemBase,
	},
	props: {
		notification: {
			type: Object,
			required: true,
		},
	},
};
</script>
