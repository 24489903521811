import Vue from 'vue';

const state = () => ({
	wsEndpoints: [],
});

const getters = {
	getWsEndpoints: (state) => state.wsEndpoints,
};

export const mutations = {
	setWsEndpoints: (state, wsEndpoints) => {
		state.wsEndpoints = wsEndpoints;
	},
};

const actions = {
	async setWsEndpoints({ commit }) {
		const db = Vue.firebase.firestore();
		const query = db.doc('settings/default');
		query.onSnapshot((doc) => {
			const { ws_endpoints } = doc.data();
			commit('setWsEndpoints', ws_endpoints);
		});
	},
};

export default {
	state,
	getters,
	mutations,
	actions,
	namespaced: true,
};
