/**
 * Format a Unix timestamp in milliseconds to a human-readable date and time.
 *
 * @param {number} unixTimestamp - The Unix timestamp in milliseconds.
 * @param {string} locale - The locale for formatting
 * @returns {string} The formatted date and time in "MM/DD/YYYY, HH:MM AM/PM" format.
 */

export default function (unixTimestamp, locale) {
	const date = new Date(unixTimestamp);
	const options = {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
	};
	return new Intl.DateTimeFormat(locale, options).format(date);
}
