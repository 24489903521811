<template>
	<b-container class="modal-body">
		<div class="h3 mb-5">{{ $t('payments.chooseAmount') }}</div>
		<div class="mb-3">
			<div class="position-relative input-wrapper mb-2">
				<b-form-input
					class="credits-input form-control"
					type="number"
					:value="creditsToBuy"
					@input="changeCreditsToBuy"
				/>
				<button v-show="sliderPosition > 0" class="clear-button" @click="changeCreditsToBuy(0)">
					<i class="fas fa-times"></i>
				</button>
			</div>
			<b-form-input class="slider" type="range" step="0.01" :value="sliderPosition" @input="changeSliderPosition" />
		</div>
		<div class="cost-text">
			<template v-if="creditsToBuy <= 0">{{ $t('payments.chooseValidAmount') }}</template>
			<template v-else-if="creditsToBuy >= 10000">{{ $t('payments.valuesAboveLimit') }}</template>
			<template v-else
				>{{ $tc('payments.creditsWillCost', creditsToBuy) }} <b>R${{ priceToPay }}</b></template
			>
		</div>
		<credits-modal-buttons
			:can-continue="validAmount"
			@step-forward="$emit('step-forward')"
			@step-backward="$emit('step-backward')"
		>
			<template #backward>
				<i class="mr-1 fas fa-times"></i>
				<strong>{{ $t('cancel') }}</strong>
			</template>
			<template #forward>
				<strong>{{ $t('next') }}</strong>
				<i class="ml-1 fas fa-angle-double-right"></i>
			</template>
		</credits-modal-buttons>
	</b-container>
</template>

<script>
import CreditsModalButtons from './CreditsModalButtons.vue';
import calculateCharge from '@/util/calculateCharge.js';

export default {
	components: {
		CreditsModalButtons,
	},
	props: {
		initialValue: {
			type: Number,
			required: true,
		},
	},

	data() {
		return {
			sliderPosition: this.getSlidePosition(this.initialValue),
			creditsToBuy: this.initialValue,
			amountButtons: [10, 30, 60],
		};
	},

	computed: {
		priceToPay() {
			return calculateCharge(this.creditsToBuy);
		},

		validAmount() {
			return this.creditsToBuy > 0 && this.creditsToBuy < 10000;
		},
	},

	watch: {
		creditsToBuy: function () {
			this.$emit('amount-change', parseInt(this.creditsToBuy));
		},
	},

	methods: {
		increaseCredits(amount) {
			this.creditsToBuy = parseInt(this.creditsToBuy) + amount;
		},

		getSlidePosition(minutes) {
			if (minutes >= 0 && minutes <= 120) {
				return minutes / 5;
			} else if (minutes <= 300) {
				return 24 + (minutes - 120) / 10;
			} else if (minutes <= 1000) {
				return 42 + (minutes - 300) / 20;
			} else if (minutes <= 10000) {
				return 77 + (minutes - 1000) / 500;
			} else if (minutes <= 15000) {
				return 95 + (minutes - 1) * 1000;
			} else return 100;
		},

		changeSliderPosition(value) {
			value = Math.round((value / 1000) * 1000);
			this.sliderPosition = value;
			if (value >= 0 && value <= 24) {
				this.creditsToBuy = value * 5;
			} else if (value <= 42) {
				this.creditsToBuy = 120 + (value - 24) * 10;
			} else if (value <= 77) {
				this.creditsToBuy = 300 + (value - 42) * 20;
			} else if (value <= 95) {
				this.creditsToBuy = 1000 + (value - 77) * 500;
			} else {
				this.creditsToBuy = 10000 + (value - 95) * 1000;
			}
		},

		changeCreditsToBuy(value) {
			this.creditsToBuy = value;
			this.sliderPosition = this.getSlidePosition(value);
		},
	},
};
</script>

<style lang="scss" scoped>
.modal-body {
	max-width: 720px;
	text-align: center;
}

.slider {
	max-width: 320px;
}

.input-wrapper {
	width: 150px;
	margin-inline: auto;
	position: relative;
	font-size: 2rem;
	line-height: 1;
}

.clear-button {
	position: absolute;
	top: 50%;
	font-size: 1.4rem;
	right: 1rem;
	translate: 0 -45%;
	border: none;
	background: none;
	opacity: 0.6;
	border-radius: 100%;
	transition: background-color 250ms;

	&:hover {
		background-color: #e9ecef;
		opacity: 0.8;
	}
}

.credits-input {
	font-size: 2rem;
	margin-inline: auto;
	text-align: center;
	padding-right: 2rem;

	/* Firefox */
	-moz-appearance: textfield;
	appearance: textfield;

	/* Chrome, Safari, Edge, Opera */
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}
</style>
