<template>
	<div>
		<form action="#" role="form" class="px-md-4 px-lg-5 px-xl-4">
			<div v-if="!succeed">
				<h5 class="d-flex justify-content-center">{{ $t('createAccount') }}</h5>
				<div class="form-group pt-3">
					<label for="name" aria-label="Name">{{ $t('name') }}</label>
					<input id="name" ref="nameInput" v-model="form.name" v-focus type="text" class="form-control" />
					<small v-if="nameError" class="text-danger font-weight-bold">
						{{ $t('nameMustBeValid') }}
					</small>
				</div>
				<div class="form-group pb-3">
					<label for="email" aria-label="Email">{{ $t('email') }}</label>
					<input
						id="email"
						ref="emailInput"
						v-model="form.email"
						v-focus
						type="email"
						class="form-control"
						:class="$v.form.email.$error ? 'is-invalid' : ''"
						autocomplete="email"
					/>
					<small v-show="$v.form.email.$error" class="text-danger font-weight-bold">
						{{
							!$v.form.email.required
								? $t('emailFieldMandatory')
								: !$v.form.email.email
								? $t('emailFieldMustBeValid')
								: ''
						}}
					</small>
				</div>
				<div class="form-group">
					<button
						id="registerBtn"
						type="submit"
						class="btn btn-pink btn-block btn-lg rounded rounded-pill text-uppercase"
						@click.prevent="submitRegister"
					>
						{{ $t('registerNewUser') }}
					</button>
				</div>
			</div>
			<div v-else class="form-group text-center">
				<p>
					{{ $t('registerEmailSent') }}<span class="font-weight-bold">{{ form.email }}</span>
				</p>
				<small>
					{{ $t('emailNotFoundTrySearchingInSpam') }}
				</small>
				<br />
				<a href="#" class="text-light font-weight-bold" @click.prevent="submitRegister">
					{{ $t('sendAgain') }}
				</a>
			</div>
		</form>
	</div>
</template>

<script>
import Vue from 'vue';

import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';

export default {
	name: 'ForgotPasswordForm',
	mixins: [validationMixin],

	data() {
		return {
			error: null,
			form: {
				name: '',
				email: '',
			},
			dismissSecs: 3,
			dismissCountDown: 0,
			nameError: false,
			message: '',
			succeed: false,
		};
	},

	validations: {
		form: {
			name: {
				required,
			},
			email: {
				required,
				email,
			},
		},
	},

	mounted() {
		this.$refs.nameInput.focus();
	},

	methods: {
		countDownChanged(dismissCountDown) {
			this.dismissCountDown = dismissCountDown;
		},

		showError(errorCode) {
			this.dismissCountDown = this.dismissSecs;
			switch (errorCode) {
				case 'auth/user-not-found':
					this.message = this.$t('emailNotFound');
					break;
				case 'auth/invalid-email':
					this.message = this.$t('emailFieldMustBeValid');
					break;
				default:
					this.message = errorCode;
					break;
			}
		},

		async submitRegister() {
			if (!this.form.name || this.form.name.length < 3) {
				this.nameError = true;
				return;
			} else this.nameError = false;

			this.$v.$touch();
			if (this.$v.$invalid) return;

			const loader = this.$loading.show();
			try {
				await this.$store.dispatch('user/sendUserInvite', this.form);
				this.registerUserSucceded();
			} catch (error) {
				console.error(error);
				Vue.swal({
					icon: 'warning',
					title: 'Oops...',
					text: error.message,
					showCancelButton: false,
					confirmButtonText: 'Voltar',
				});
			} finally {
				this.error = null;
				loader.hide();
			}
		},

		registerUserSucceded() {
			this.succeed = true;
		},
	},
};
</script>
